<template>
    <div class="section">
        <div class="section-inner">
            <p class="small-title">RS Melinda 2</p>
            <h1 class="content-title text-white">Video RS Melinda 2</h1>
            <p class="section-inner-description">Temukan berbagai video kesehatan di sini.....</p>
        </div>
        <div class="video-search">
            <!-- {{ videoCategories }} -->
            <div class="video-search-inner" v-if="videoCategories">
                <div class="video-search-select">
                    <div class="vss-inner">
                        <div class="vss-btn rotatable-arrow-related" @click="vssSelect">
                            <div class="vss-btn-text rotatable-arrow-related" id="vss-btn-text">
                                <span>Pilih Kategori</span>
                            </div>
                            <div class="vss-btn-icon rotatable-arrow-related">
                                <img class="rotatable-arrow rotatable-arrow-related" src="../../assets/images/arrow-1.png" alt="rumah sakit melinda 2">
                            </div>
                        </div>
                        <div class="vss-select floating-element" id="vss-select">
                            <div class="vss-option" @click="setVideoByCategory(0, 'Semua')">Semua</div>
                            <div class="vss-option" v-for="category in videoCategories" :key="category.id" @click="setVideoByCategory(category.slug, category.name)">{{ category.name }}</div>
                        </div>
                    </div>
                </div>
                <div class="video-search-input">
                    <input type="text" id="vsi-input" placeholder="Cari video" />
                </div>
                <div class="video-search-button" @click="searchVideos">
                    <span>Search</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from "vue";
    import { useVideoStore } from "@/stores/video";
    function vssSelect(){
        const vs = document.getElementById('vss-select');
        const arrow = vs.previousElementSibling.children[1].children[0];
        if(vs.style.display == 'block'){
            vs.style.display = 'none';
            arrow.style.transform = 'rotate(0)';
        }else{
            vs.style.display = 'block';
            arrow.style.transform = 'rotate(180deg)';
        }
    }
    function setVideoByCategory(slug, name){
        useVideoStore().setVideosByCategory(slug);
        document.getElementById('vss-btn-text').innerHTML = '<span>' + name + '</span>';
        vssSelect();
    }
    function searchVideos(){
        const vi = document.getElementById('vsi-input');
        useVideoStore().searchVideos(vi.value);
    }
    const videoCategories = computed(() => useVideoStore().videoCategories);
    useVideoStore().setVideoCategories();
</script>

<style scoped>
    .section{
        position: relative;
        padding: 2rem;
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 3.5rem;
        background: #1B4674;
        color: #ffffff;
        /* z-index: 11; */
        /* border: 1px solid red; */
    }
    .small-title{
        margin: 0;
        color: #75C6EF;
    }
    .section-inner{
        position: relative;
    }
    .section-inner h1{
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .section-inner-description{
        margin: 0;
        color: #ffffff;
    }
    .video-search{
        /* display: none; */
        position: absolute;
        left: 0;
        bottom: -15px;
        width: 100%;
        height: 50px;
        padding: 1rem;
        padding-left: 5%;
        padding-right: 5%;
        /* z-index: 1; */
    }
    .video-search-inner{
        /* position: relative; */
        display: flex;
        flex-wrap: wrap;
        color: grey;
        background: #ffffff;
        /* border: 1px solid lightgrey; */
    }
    .video-search-select{
        /* position: relative; */
        width: 45%;
        /* border: 1px solid lightgrey; */
        transition: 0.2s;
    }
    .vss-inner{
        position: relative;
        border: 1px solid lightgrey;
    }
    .vss-btn{
        display: flex;
        flex-wrap: nowrap;
        padding: 1%;
        user-select: none;
    }
    .vss-btn-text{
        width: 95%;
    }
    .vss-btn-icon{
        width: 5%;
        text-align: center;
    }
    .vss-btn-icon img{
        max-width: 100%;
        transition: 0.2s;
    }
    .vss-select{
        display: none;
        position: absolute;
        top: 110%;
        left: 0;
        width: 100%;
        background: #ffffff;
        padding: 1%;
        border: 1px solid lightgrey;
        user-select: none;
    }
    .video-search-input{
        width: 45%;
        border: 1px solid lightgrey;
        border-left: none;
        transition: 0.2s;
    }
    .video-search-input input{
        width: 100%;
        height: 100%;
        outline: none;
        padding: 1%;
        border: none;
        color: grey;
    }
    .video-search-button{
        display: flex;
        width: 10%;
        background: #75C6EF;
        color: #ffffff;
        /* text-align: center; */
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.2s;
    }
    @media only screen and (max-width: 1000px){
        .video-search-select{
            width: 100%;
            margin-bottom: 0.5rem;
        }
        .video-search-input{
            width: 75%;
            border-left: 1px solid lightgrey;
        }
        .video-search-button{
            width: 25%;
        }
    }
    @media only screen and (max-width: 500px){
        .video-search-input{
            width: 100%;
            margin-bottom: 0.5rem;
        }
        .video-search-button{
            width: 100%;
        }
    }
    /*  */
</style>