<script>
    import HeadingContent from "@/components/utils/HeadingContent.vue";    
    export default {
        components: {
            HeadingContent
        },
        props: {
            data_services: Array,            
        }               
    }
</script>

<template>    
    <section id="carousel-service" class="container-fluid carousel slide mt-5" data-bs-ride="carousel">
        <div class="carousel-inner">
            <template v-for="(da, index) in data_services" :key="da.service_id.trim()">
                <div class="carousel-item" :class="{'active': index == 0}">
                    <div class="row">
                        <div class="col-sm-6 px-5 py-5 py-md-0 my-auto">
                            <HeadingContent 
                                :title="da.name"
                                :paragraph="da.description"
                                text_dark                                
                            >
                                <a :href="'/service-detail/'+da.service_id" class="btn-readmore btn btn-primary rounded-pill">
                                    View Service
                                </a>
                            </HeadingContent>
                        </div>
                        <div class="col-sm-6 position-relative">
                            <template v-if="da.img">
                                <img :src="da.img_url" class="img-carousel card-img" :alt="da.name">
                            </template>
                            <template v-else>
                                <img :src="da.img_url.split('https://backend2.melindadev.com/images/article/').pop()" class="img-carousel card-img" :alt="da.name">
                            </template>
                            <div class="position-absolute bottom-0 end-0 ps-0 pe-2 col-sm-12 d-flex justify-content-end">
                                <button class="btn-control-prev" type="button" data-bs-target="#carousel-service" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>       
                                <button class="btn-control-next" type="button" data-bs-target="#carousel-service" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>         
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<style scoped>
    .img-carousel {
        background-size: cover;
        object-fit: cover;        
        /* height: 90vh; */
    }
    .controller-info {
        background-color:#ffffff;
    }
    .btn-pendaftaran {
        background: #75C6EF;
        border: transparent;       
    }
    .btn-pendaftaran:hover {
        background: #75C6EF;
        border: transparent;
        opacity: 0.5;       
    }
    .btn-control-next {
        background: #75C6EF;
        border: transparent;
        color: white;
    }
    .btn-control-next:hover {
        background: #75C6EF;
        border: transparent;
        color: white;
        opacity: 0.8;
    }
    .btn-control-prev {
        background: #75c6ef7a;
        border: transparent;
        color: white;
    }
    .btn-control-prev:hover {
        background: #75C6EF;
        border: transparent;
        color: white;
        opacity: 0.8;
    }
    .btn-readmore {
        color: #575757;
        background-color: #FCE8CE;
        border: transparent;
    }
    .btn-readmore:hover {
        color: #575757;
        background-color: #FCE8CE;
        border: transparent;
        opacity: 0.8;
    }
    .carousel-item .row {
        background-color:#ffffff;
    }
</style>