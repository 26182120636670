<script>
    import FooterContent from "@/components/utils/FooterContent.vue"
    import ContentImages from "@/components/utils/ContentImages.vue"
    import HeroText from "@/components/utils/HeroText.vue"    
    import axios from "axios"
    export default {
        components: {
            FooterContent, HeroText, ContentImages
        },
        data(){
            return {
                data_service : [],
                data_service_length : null,
                header: {},
                serviceTags: [],
                serviceTagSelected: "Semua",
                search: '',
                val_search: '',
                limit: 6,
                avaliable_load_more: true,
            }
        },
        mounted(){
            window.scrollTo(0, 0);
            axios.get('https://backend2.melindadev.com/api/page-header/service')
            .then(response => {
                this.header = response.data.page_header;                
            });
            axios.get('https://backend2.melindadev.com/api/service')
            .then(response => {
                this.data_service = response.data.services;
                this.data_service_length = response.data.services.length;
                this.serviceTags = response.data.categories;
            });
        },
        computed: {
            filteredServices() {   
                let slicedServices;             
                // cek apakah tag itu bukan "Semua"
                if (this.serviceTagSelected != "Semua") {
                    slicedServices = this.data_service;                    
                }else {
                    slicedServices = this.data_service.slice(0, -2);                    
                }
                if (this.search.length > 0) {             
                    return slicedServices.filter(r => {
                        return r.name.toLowerCase().includes(this.search.toLowerCase());
                    });
                }
                else if(this.serviceTagSelected != "Semua"){
                    return slicedServices.filter(r => {
                        return r.category.name === this.serviceTagSelected;
                    });                    
                }
                return slicedServices
            },
        },
        methods: {
            searchButtonHandle() {
                this.search = this.val_search;                
            },
            loadMore() {
                if (this.limit < this.data_service_length) {
                    this.limit = this.limit + 6
                }else {
                    this.limit = this.limit * 2
                    this.avaliable_load_more = false
                }
            },
        }
    }
</script>

<template>    
    <HeroText 
        :subTitle="header.sub_title" 
        :title="header.title" 
        :content="header.description"
        blueTheme
    />
    <div class="container mx-auto py-4 input-services d-none d-md-block">
        <!-- input group -->
        <div class="input-group mb-5 container">
            <select class="form-select" aria-label="Default select example" name="serviceTagSelected" v-model="serviceTagSelected">
                <option value="Semua">Semua</option>
                <option v-for="(serviceTag) in serviceTags" :key="serviceTag" v-bind:value="serviceTag.name">
                    {{serviceTag.name}}
                </option>                
            </select>
            <input 
                v-model="val_search" 
                type="text" 
                class="form-control" 
                id="basic-url" 
                aria-describedby="basic-addon3" 
                placeholder="Cari disini..."
                required 
            />
            <button type="button" class="btn btn-primary py-3 btn-search" @click="searchButtonHandle">
                Search Services
            </button>
        </div>
    </div>
    <!-- input mobile -->
    <div class="d-block d-md-none py-4">
        <div class="input-group container d-flex flex-column gap-4">
            <select class="form-select w-100" aria-label="Default select example" name="serviceTagSelected" v-model="serviceTagSelected">
                <option value="Semua">Semua</option>
                <option v-for="(serviceTag) in serviceTags" :key="serviceTag" v-bind:value="serviceTag.name">
                    {{serviceTag.name}}
                </option>                
            </select>
            <input 
                v-model="val_search" 
                type="text" 
                class="form-control w-100" 
                id="basic-url" 
                aria-describedby="basic-addon3" 
                placeholder="Cari disini..."
                required 
            />
            <button type="button" class="w-100 btn btn-primary py-3 btn-search" @click="searchButtonHandle">
                Search Services
            </button>
        </div>
    </div>
    <template v-for="(dataservice, index) in filteredServices.slice(0, limit)" :key="dataservice.service_id.trim()">
        <template v-if="index % 2 == 1">
            <template v-if="dataservice.name.toLowerCase().includes(search.toLowerCase())">
                <ContentImages noBackground
                    :subTitle="dataservice.category.name"
                    :title="dataservice.name"
                    :paragraph="dataservice.description"
                    isSliced
                >
                    <template v-slot:extra>
                        <a :href="'/service-detail/'+dataservice.service_id" class="my-4 btn btn-primary link-to-website">Lihat Detai <i class="bi bi-chevron-right"></i></a>
                    </template>
                    <img :src="dataservice.img_url" alt="services" class="img-fluid" style="object-fit: cover;">    
                </ContentImages>
            </template>
        </template>
        <template v-else>
            <template v-if="dataservice.name.toLowerCase().includes(search.toLowerCase())">
                <ContentImages noBackground
                    isSwaped
                    :subTitle="dataservice.category.name"
                    :title="dataservice.name"
                    :paragraph="dataservice.description"
                    isSliced
                >
                    <template v-slot:extra>
                        <a :href="'/service-detail/'+dataservice.service_id" class="my-4 btn btn-primary link-to-website">
                            Lihat Detail <i class="bi bi-chevron-right"></i>
                        </a>
                    </template>
                    <img :src="dataservice.img_url" alt="services" class="img-fluid" style="object-fit: cover;">    
                </ContentImages>
            </template>
        </template>        
    </template>
    <template v-if="limit < data_service_length && avaliable_load_more">
        <div class="container d-flex my-4" >
            <button class="btn btn-primary mx-auto load-more" @click="loadMore">Load More</button>
        </div>
    </template>
    <FooterContent/> 
</template>

<style scoped>
    .load-more {
        background: #d8af79;
        border-radius: 40px;
        color: #ffffff;
        border: 1px solid transparent;
    }
    .load-more:hover {
        background: #ffffff;
        border-radius: 40px;
        color: #545454;
        border: 1px solid #d8af79;
    }
    .link-to-website {
        background: #75C6EF;
        border-radius: 40px;
        border: 1px solid transparent;
    }
    .link-to-website:hover {
        background: #ffffff;
        border-radius: 40px;
        color: #75C6EF;
        border: 1px solid #75C6EF;
    }
    .input-services {
        margin-top: -40px;
    }
    .btn-search {
        background: #75C6EF;
        border: transparent;
        color: #ffffff;
    }
    .btn-search:hover {
        opacity: 0.5;
        background: #75C6EF;
        border: transparent;
        color: #ffffff;
    }
    .konten {
        padding: 48px 10% 48px 10%;     
    }

    .no-background {
        background-color: #ffffff00;
    }
    .background-blue {
        background-color: #1B4674;
    }

    .background-cream {
        background-color: #FDF4E9;
    }
    p.fw-light {
        line-height: 150%;
        color: #575757;
    }
    #hero-images-homepage {
        width: 50%;    
        display: block;    
    }

    .wrapper-hero-img {
        margin-top: -30%;
        z-index: 998;
        display: flex;
        justify-content: end;    
    }

    .mouse {
        width: 34px;
    }

    .bg-img-1 {
        height: 460px;
        background-image: url("@/assets/images/services-img-3.png");
        background-size: cover;
        object-fit: cover;    
    }

    .bg-img-2 {
        height: 460px;
        background-image: url("@/assets/images/services-img-4.png");
        background-size: cover;
        object-fit: cover;    
    }

    .bg-img-1 p, .bg-img-2 p {
        color: white !important;
        z-index: 1000;
        filter: brightness(100%) !important;
    }

    @media (max-width: 575px) {
        .wrapper-hero-img {
            margin-top: 0;
            z-index: 0;
            display: none;
            justify-content: end;    
        }
    }

    @media (max-width: 1199.98px) {
        .wrapper-hero-img {
            margin-top: 0;
            z-index: 0;
            display: flex;
            justify-content: end;    
        }
    }
</style>