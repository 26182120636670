<template>
    <HeroText
      blueTheme
      :subTitle="header.sub_title"
      :title="header.title"
      :content="header.description"
    />
    <ContentImages        
        :subTitle="infoHistory.sub_title"
        :title="infoHistory.title"
        :paragraphHtml="infoHistory.description"
    >
        <img :src="infoHistory.img_url" alt="services" class="img-fluid" style="object-fit: cover;">
    </ContentImages>
    <VisiMisi />
    <KebijakanMutu />
    <OurFilosofy />
    <HospitalPlan />
    <FooterContent />
</template>

<script>
    import VisiMisi from "@/components/about/VisiMisi.vue"
    import ContentImages from "@/components/utils/ContentImages.vue"
    import FooterContent from "@/components/utils/FooterContent.vue"
    import HospitalPlan from "@/components/about/HospitalPlan.vue"
    import KebijakanMutu from "@/components/about/KebijakanMutu.vue"
    import HeroText from "@/components/utils/HeroText.vue"
    import OurFilosofy from "@/components/about/OurFilosofy.vue"    
    import axios from "axios"
    export default {
        components: {
            ContentImages,  FooterContent, VisiMisi, HospitalPlan, KebijakanMutu, HeroText, OurFilosofy
        },
        data() {
            return {
                infoHistory: [],
                filo: [],
                header: []
            }
        },
        mounted() {
            axios.get('https://backend2.melindadev.com/api/about')
            .then(response => (this.infoHistory = response.data.history),response => (this.filo = response.data.filosofy))
            axios.get('https://backend2.melindadev.com/api/page-header/about').then(
                response => this.header = response.data.page_header
            )
        },
    }   
</script>

<style scoped>

</style>