<script>
    import HeadingContent from "@/components/utils/HeadingContent.vue"  
    export default {
        props: {
            services: Array,
        },
        components: { HeadingContent }
    }
</script>

<template>    
    <div class="container-fluid background-cream">
        <div class="container py-5 row mx-auto">
        <div class="col-sm-6">
            <HeadingContent 
            subTitle="Stay strong and lets beat Cervical Cancer!" 
            title="Our Great Services for Your Family Care" 
            >
            <a href="/our-services" class="btn-pendaftaran btn btn-primary rounded-pill">
                View All Services
            </a>
            </HeadingContent>
        </div>        
        <div class="col-sm-6 row">
            <div class="col-sm-6" v-for="(listService, index) in services" :key="listService.name">
            <template v-if="index < 10">
                <a :href="'/service-detail/'+listService.service_id"><p class="text-services fw-bold">{{ listService.name }}</p></a>
            </template>
            </div>
        </div>
        </div>
    </div>    
</template>

<style scoped>
  a .text-services:hover {
    opacity: 0.8;
  }
  .btn-pendaftaran, .link-to-readmore {
      background: #75C6EF;
      border: transparent;       
  }
  .btn-pendaftaran:hover {
      background: #75C6EF;
      border: transparent;
      opacity: 0.5;       
  }
  .text-services {
    color: #1B4674;
  }
</style>