<script>
    import axios from 'axios'
    import { Head } from '@vueuse/head'
    export default {
        data(){
            return { 
                layout : []
             }
        },
        components: {
            Head,
        },
        methods: {
            // buat function yang dimana ketika diklik maka akan dialihkan ke halaman detail
            goToDetail(){
                this.$router.push('/our-room-detail'); 
            },
            menuButton(){
                document.getElementById('menu-button').click();
            },
            scrollTop() {
                window.scrollTo(0, 0);                
            }
        },
        mounted() {
            window.onpopstate = function() {
                window.location.reload()
            }
            axios.get('https://backend2.melindadev.com/api/layout')
            .then(response => (this.layout = response.data))
        },
    }
</script>
<template>
    <section id="main-app">
        <!-- Sekarang untuk mengatur data head disini -->
        <Head>
            <meta charset="utf-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width,initial-scale=1.0">
            <link rel="icon" :href="layout.logo_tab">
            <title>RS Melinda 2</title>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css">
        </Head>
        <nav class="navbar navbar-expand-lg navbar-light nav-fixed">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">
                <img v-bind:src="layout.logo">
            </a>
            <button class="navbar-toggler" id="menu-button" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div class="navbar-nav ms-auto">                    
                <router-link class="nav-link" to="/" @click="menuButton">Home</router-link>
                <router-link class="nav-link" to="/our-services" @click="menuButton">Services</router-link>
                <!-- <router-link class="nav-link" to="/about">About</router-link> -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        About
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><router-link class="nav-link" to="/about" @click="menuButton">About</router-link></li>
                        <!-- <li><router-link class="nav-link" to="/career">Career</router-link></li> -->
                        <!-- <li><router-link class="nav-link" to="/auction-jobs">Auction</router-link></li>                     -->
                        <li><a class="nav-link" href="https://melindahospital.com/articles" target="_blank">Article</a></li>                    
                    </ul>
                </li>
                <router-link class="nav-link" to="/our-room" @click="menuButton">Room</router-link>
                <router-link class="nav-link" to="/gallery" @click="menuButton">Gallery</router-link>
                <router-link class="nav-link" to="/video" @click="menuButton">Video</router-link>
                <router-link class="nav-link" to="/doctors" @click="menuButton">Doctor</router-link>            
                <router-link class="nav-link" to="/links" @click="menuButton">Links</router-link>                    
                <a class="nav-link" href="https://morula.rsmelinda2.com" target="_blank" @click="menuButton">Morula</a>                    
                <router-link to="/contact" class="btn btn-primary" href="/contact" role="button" @click="menuButton">Contact Us</router-link>                 
            </div>
            </div>
        </div>
        </nav>
        <router-view/>
        <div class="link-tree-icon">
            <a href="https://www.linktr.ee/rsmelinda2" target="_blank">
                <img src="./assets/images/link-icon.png" alt="melinda hospital">
            </a>
        </div>
        <div class="btn-up-scroll" @click="scrollTop">
            <i class="bi bi-chevron-up"></i>
        </div>
    </section>
</template>

<style>
    /* ALL */
    @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
    :root {
		--primary: #75C6EF;
		--primary-hover: #65b7e0;
		--white: #ffffff;
		--grey: #575757;
		--grey-hover: #6d6b6b;
		--softgrey: lightgrey;
		--smoke: whitesmoke;
		--navy: #1B4674;
		--red: #CD4051;
		--red-hover: #b13746;
		--cream: #FCE8CE;
		--cream-bg: #FEF8F0;
		--softcream: #F5F4F4;
	}
    #main-app{
        margin-top: 75px;
        /* border: 1px solid green; */
    }
    .link-tree-icon{
        position: fixed;
        right: 50px;
        bottom: 2.5%;
        width: 75px;
        height: 75px;
        background: #ffffff;
        display: flex;
        /* text-align: center; */
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 75px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
        z-index: 100000000000;
        overflow: hidden;
        transition: 0.5s;
    }
    .link-tree-icon img{
        width: 100%;
        height: 100%;
        transition: 0.5s;
    }
    .btn-up-scroll {
        display: flex;
        position: fixed;
        bottom: -15%;
        right: 50px;
        background: #00000079;
        color: #ffffff;
        /* border: transparent; */
        border-radius: 5px;
        opacity: 0.9;
        /* padding: 15px; */
        width: 50px;
        height: 50px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.5s;
        z-index: 100000000000;
    }
    .btn-up-scroll:hover{
        background: #000000b6;
    }
    h1,h2,h3,h4,h5,h6 {
        font-family: 'Poppins', sans-serif;
    }
    a, p {
        font-family: 'Roboto', sans-serif;
        color: #575757;
        text-align: justify;
    }
    .content-sub-title {
        color: #75C6EF;
        font-weight: 600;
        font-size: 16px;
    }
    .content-title {
        font-weight: bolder !important;
        color: #1B4674;
        margin-bottom: 14px;
    }
    .content-text {
        color: #575757;
        line-height: 150%;
        margin-bottom: 28px;
    }
    .navbar {
        background-color: #ffffff !important;
    }
    nav {
        padding: 40px 0 40px 0;
        z-index: 988 !important;      
    }
    .nav-fixed{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid whitesmoke;
    }
    .nav-link {
        font-size: 14px !important;
        font-weight: 600;
        margin: 0 20px 0 20px;
        color: #575757 !important;        
    }

    .nav-link.active {
        color:#75C6EF !important;
    }

    a.navbar-brand img {
        width: 160px;
    }

    .navbar-nav a.btn.btn-primary {
        margin: 0 20px 0 20px;    
        display: flex;
        align-items: center;
        color: #575757 !important;
        font-weight: 600; 
        background-color: #FCE8CE !important;
        border: none;
        border-radius: 40px;
        font-size: 14px !important;   
        white-space: nowrap; 
    }
    nav a.router-link-exact-active {
        color:#75C6EF !important;
    }
    .wrapper-hero-img {
        margin-top: -38%;
        z-index: -1000 !important;
        display: flex;
        justify-content: end;    
    }
    .cream-background {
        z-index: -999;    
        background-color: #FCE8CE;
        margin-top: -390px;
        height: 540px;
        width: 100%;    
    }
    @media (max-width: 1199.98px) {
        .konten {
            padding: 48px 8% 48px 8%; 
            background-color: #1B4674;
        }
        .navbar-nav a.btn.btn-primary { 
            margin: 0 !important;
            display: flex;
            align-items: center;
            color: #575757 !important;
            font-weight: 600; 
            background-color: #FCE8CE !important;
            border: none;
            border-radius: 40px;
            font-size: 14px !important;    
        }  
        .nav-link {
            font-size: 14px !important;
            font-weight: 600;
            margin: 0 5px 0 5px;
            color: #575757 !important;        
        }
        footer #contact {        
            padding: 48px 8% 48px 8%;
            justify-content: space-between;
            background-color: #FEF8F0;        
        }
        footer hr { width: 84%; }
    }
    /* previously 575px */
    @media (max-width: 991px) {
        .konten {
            padding: 48px 5% 48px 5%; 
            background-color: #1B4674;
        }
        .navbar-nav a.btn.btn-primary {         
            margin: 0 !important;
            display: flex;
            align-items: center;
            color: #575757 !important;
            font-weight: 600; 
            background-color: #FCE8CE !important;
            border: none;
            border-radius: 40px;
            font-size: 14px !important;
            padding-left: 10px;
        } 
        nav {
            padding: 40px 10px 40px 10px;    
        }
        #contact #desc p { width: 100%; color: #1B4674; font-weight: 600; font-size: 12px; margin-bottom: 0 !important;}    
        footer #contact {
            width: 100%;        
            padding: 48px 5% 48px 5%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: #FEF8F0;
        }
        footer hr { width: 90%; }
    }
</style>