import { defineStore } from "pinia";
import axiosClient from "../axios.js";

export const useGalleryStore = defineStore("gallery", {
    state: () => ({
        // ==================== states used on gallery page and its relation
        galleries: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on gallery page and its relation
        async setGalleries(id){
            const response = await axiosClient.get(`art/` + id);
            this.galleries = response.data.art.galeries;
        }
    }
});