<template>    
  <section class="d-block" :class="{'blue-theme':blueTheme, 'red-theme':redTheme}">
      <div class="container d-flex justify-content-start flex-column links-hero gap-2 py-5">
        <span class="content-sub-title" :class="{'text-start':!textCenter, 'text-center': textCenter}">{{subTitle}}</span>
        <h1 class="content-title" :class="{'text-white':blueTheme||redTheme,'text-start':!textCenter, 'text-center': textCenter}" >
            {{title}}
        </h1>
        <div 
        v-if="content"
        class="links-desc" :class="{'text-white':blueTheme||redTheme,'text-start':!textCenter, 'text-center': textCenter, 'text-dark':textDark}">
            <p :class="{'text-dark':textDark}">
                {{ filteredContent }}
            </p>     
        </div>
        <slot>
        </slot>
      </div>
  </section>
</template>

<script>
    const { htmlToText } = require('html-to-text')
    export default {
        props: {
            title: String,
            subTitle: String,
            content: String,
            blueTheme: Boolean,
            redTheme: Boolean,
            textCenter: Boolean,
            textDark: Boolean
        },
        computed: {
            filteredContent() {
                let text = htmlToText(this.content);
                return text;
            }
        }
    }    
</script>

<style scoped>
    a, p {
        font-family: 'Roboto', sans-serif;
        color: #ffffff !important;
    }
    .blue-theme {
        background-color: #1B4674;
    }
    .red-theme {
        background-color: #CD4051 !important;
    }
    .links-desc p {
        text-align: justify;
    }
    .text-dark {
        color: #575757 !important;
    }
</style>