<template>
    <span class="align-self-start content-sub-title mb-1">{{subTitle}}</span>
    <template v-if="!headingLink">
      <h1 class="align-self-start heading fw-bold mb-4" :class="{ 'teks-biru' : blue_title, 'text-white': white_title }" v-html="title"></h1>
    </template>
    <template v-else>
      <a class="align-self-start" :href="headingLink"><h1 class="heading fw-bold mb-4" :class="{ 'teks-biru' : blue_title, 'text-white': white_title }" v-html="title"></h1></a>
    </template>
    <p class="align-self-start fw-light" :class="{ 'text-dark' : text_dark, 'text-light' : !text_dark, 'visually-hidden' : no_text }">
      {{filteredContent}}
    </p>
    <template v-if="paragraphHtml">
      <p class="align-self-start fw-light" :class="{ 'text-dark' : text_dark, 'text-light' : !text_dark, 'visually-hidden' : no_text }" v-html="paragraphHtml">
    </p>
    </template>
    <slot></slot>
</template>

<script>
const { htmlToText } = require('html-to-text')
export default {
  props: {
    subTitle: String,
    title: String,
    paragraph: String,
    text_dark: Boolean,
    no_text: Boolean,
    blue_title: Boolean,
    white_title : Boolean,
    headingLink: Boolean,
    paragraphHtml: Boolean
  },
  computed: {
      filteredContent() {
          let text = htmlToText(this.paragraph);
          return text;
      }
  }
}
</script>

<style>
    a {
      text-decoration: none !important;
    }
    a h1:hover {
      opacity: 0.8;
    }
    .heading { font-size: 36px; }
    .teks-biru { color: #1B4674; }
    p { font-size: 16px; }
    .text-dark {
      color: #575757 !important;
    }
    @media (max-width: 575px) {
      .heading { font-size: 24px; }
      p { font-size: 12px; }
    }
    @media (max-width: 1199.98px) {
      .heading { font-size: 30px; }
      p { font-size: 14px; }
    }
</style>