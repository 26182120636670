<script>
    import axios from "axios"
    import FooterContent from "@/components/utils/FooterContent.vue"    
    import GalleryContent from "@/components/gallery/GalleryContent.vue"    
    import HeroText from "@/components/utils/HeroText.vue"
    export default {
        data() {
            return {
                header: []
            }
        },
        components: {
            FooterContent,
            GalleryContent,
            HeroText
        },
        mounted() {
            axios.get('https://backend2.melindadev.com/api/page-header/gallery')
            .then(response => {
                this.header = response.data.page_header;
            });
        }
    }
</script>

<template>
    <HeroText
      blueTheme
      :subTitle="header.sub_title"
      :title="header.title"
      :content="header.description"
    />
    <GalleryContent />
    <FooterContent />
</template>