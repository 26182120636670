<script>
  // import HeroText from "@/components/utils/HeroText.vue"
  import AboutContent from "@/components/about/AboutContent.vue"
  import json from './json/About.json'
  export default {
    data(){
      return {
        aboutData: json
      }
    },
    components: {
       AboutContent
    },
    mounted(){
      window.scrollTo(0, 0);
    }
  } 
</script>

<template>
    <AboutContent />
</template>
