<script>
    const { htmlToText } = require('html-to-text')
    import axios from "axios";
    export default {
        data() {
            return {
                arts: [],
            }
        },
        mounted() {
            axios.get('https://backend2.melindadev.com/api/art')
            .then(response => (this.arts = response.data.arts))
        },
        computed: {
            filteredContent() {
                let text = htmlToText(this.arts.description);
                return text;
            }
        }
    }
</script>

<template>
    <section class="container-fluid p-0 m-0">
        <!-- {{ arts.length - 1 }} -->
        <template v-for="(art, index) in arts.slice(0, arts.length)" :key="art.art_id">
            <template v-if="index % 2 == 0">
                <div class="container-fluid row p-0 my-0 mx-auto">
                    <div class="col-sm-7 m-0 p-0 parent">
                        <img :src="art.img_url" alt="gallery-img" class="img-fluid stack-img" />                        
                    </div>
                    <div class="col-sm-5 px-4 m-0 align-self-center">
                        <span class="content-sub-title">RSIA Melinda</span>
                        <h1 class="content-title">{{ art.title }}</h1>
                        <!-- <div v-html="art.description"></div> -->
                        <div class="my-4">
                            {{ art.description.replace(/(<([^>]+)>)/ig, "").slice(0, 200) + "..." }}
                        </div>
                        <div class="container-fluid px-0 pb-0 pt-2 mt-5 border-top">
                            <a :href="'/gallery-detail/'+art.art_id" class="link-to-website btn btn-light">
                                View Gallery <i class="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="container-fluid row p-0 my-0 mx-auto">
                    <div class="col-sm-5 px-4 m-0 align-self-center">
                        <span class="content-sub-title">RSIA Melinda</span>
                        <h1 class="content-title">{{ art.title }}</h1>
                        <div class="my-4">
                            {{ art.description.replace(/(<([^>]+)>)/ig, "").slice(0, 200) + "..." }}
                        </div>
                       <div class="container-fluid px-0 pb-0 pt-2 mt-5 border-top">
                            <a :href="'/gallery-detail/'+art.art_id" class="link-to-website btn btn-light">
                                View Gallery <i class="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                    <div class="col-sm-7 m-0 p-0">
                        <img :src="art.img_url" alt="gallery-img" class="img-fluid stack-img" />
                    </div>
                </div>
            </template>
        </template>        
    </section>
</template>

<style scoped>
    .parent {
        position: relative;
        top: 0;
        left: 0;
        object-fit: cover;
        background-size: cover;
    }
    .stack-img {
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        object-fit: cover;
        background-size: cover;
        height: 550px;
    }
    .stack-img1 {
        width: 320px;
        position: absolute;
        top: 32px;
        left: 80px;
        height: 550px;
    }
    .link-to-website {
        border-radius: 40px;
        background-color: #ffffff;
        border: 1px solid #FCE8CE;
        color: #575757;
        padding: 8 16px 8 16px;
        font-size: 14px;        
    }
    
    .link-to-website:hover {
        border-radius: 40px;
        background-color: #FCE8CE;
        color: #575757;
        padding: 8 16px 8 16px;
        font-size: 14px;
        border: 1px solid transparent;
    }
</style>