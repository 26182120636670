<script>
    // import axios from 'axios';
    import moment from 'moment';
    import FooterContent from "@/components/utils/FooterContent.vue";
    import HeroText from "@/components/utils/HeroText.vue";
    // import { setPageData, wordsLimit, removeHTML } from '@/functions/general';
    export default {
        components: {
            FooterContent, HeroText
        },
        data() {
            return {
                category: null,
                categories: [],
                article: []
            }
        },
        methods: {
            format_date(value){
                if (value) {
                    return moment(String(value)).format('DD MMMM YYYY')
                }
            },
        },
        mounted() {
            location.href = `https://melindahospital.com/articles/detail/${this.$route.params.id}`;
            // window.scrollTo(0, 0);
            // axios.get('https://backend1.melindadev.com/api/article/'+this.$route.params.id)
            // .then(response => {
            //     this.article = response.data.articles;
            //     this.categories = response.data.categories;
            //     this.category = this.article.category.name;
            //     setPageData({
            //         title: this.article.title,
            //         description: wordsLimit(removeHTML(this.article.description.replace(/(&nbsp;|<([^>]+)>)/ig, "")), 200),
            //         canonical: 'https://melindahospital.com/articles/detail/' + this.article.slug
            //     });
            // })
        },
        unmounted(){
            if(document.querySelector('link[rel="canonical"]')){
                document.querySelector('link[rel="canonical"]').remove();
            }
        }
    }
</script>

<template>
    <template v-if="article.category">
        <HeroText 
            blueTheme
            :subTitle="article.category.name" 
            :title="article.title" 
            :content="`ditulis oleh ${article.author}`" 
        />
    </template>
    <section class="container mx-auto px-0 row py-5 negative-margin">
        <div class="col-sm-8">
            <!-- article content -->
            <div class="container-fluid">
                <img :src="article.img_url" class="article-img card-img" :alt="article.title">
                <!-- <template v-if="article">
                    <img :src="article.img" class="article-img card-img" :alt="article.title">
                </template>
                <template v-else>
                    <img :src="article.img_url.split('https://backend2.melindadev.com/images/article/').pop()" class="article-img card-img" :alt="article.title">
                </template> -->
                <div class="lead my-4" v-html="article.description"></div>
            </div>
            <!-- /article content -->
        </div>
        <div class="col-sm-4">
            <div class="card-other-jobs p-4 mb-5">
                <h5>Looking for other available position ?</h5>
                <p>Melinda Hospital Jobs</p>
                <a href="/career" class="button-browse-job btn btn-priamry align-self-center btn-cream">Browse Other Job</a>
            </div>
            <h3>Categories</h3>
            <ul class="list-group mb-5">
                <li class="list-group-item" v-for="c in categories" :key="c.article_categori_id">
                    <a :href="'/articles/category/' +c.article_categori_id">{{ c.name }}</a>
                </li>
            </ul>
        </div>     
    </section>   
    <FooterContent />
</template>

<style scoped>
    @media (max-width: 576px) {
        .negative-margin {
            margin-top: 0 !important;
        }
        section.blue-theme {
            height: fit-content !important;
        }
    }
    .negative-margin {
        margin-top: -120px;
    }
    section.blue-theme {
        height: 400px;
    }
    .badge {
        background-color: #FCE8CE;
        color: #474747;        
    }
    .badge:hover {
        background-color: #ffffff;
        color: #474747;
        cursor: pointer;
    }
    ul.list-group .list-group-item {
        padding-left: 0px;
        background-color: transparent;
        border-top: 1px solid transparent;
        border-right: 1px solid transparent;
        border-bottom: 1px solid #474747;
        border-left: 1px solid transparent;
    }
    ul.list-group .list-group-item a {
        font-weight: 600;
        text-decoration: none;
    }
    ul.list-group .list-group-item a:hover {
        text-decoration: none;
        opacity: 0.5;
    }
    h3 {
        color: #1B4674;
    }
    .card-other-jobs {
        background: #75C6EF;
    }
    .card-other-jobs h5 { color: #1B4674; font-weight: bold; font-size: 18px }
    .card-other-jobs p { color: #ffffff; font-size: 14px }
    .btn-cream {
        background-color: #FCE8CE !important;
        color: #575757 !important;
        font-size: 14px !important;
        padding: 8px 24px 8px 24px !important;
        border-radius: 40px;
    }
    .btn-cream:hover {
        background-color: #e7cca7 !important;
        color: #575757 !important;
        font-size: 14px !important;
        padding: 8px 24px 8px 24px !important;
    }
</style>