<template>
    <section>
        <div class="section-inner">
            <div class="section-head">
                <HeadingContent 
                subTitle="Melinda Hospital Video"
                title="Video Kesehatan"
                no_text
            />
            </div>
            <div class="section-body">
                <div class="video-list" v-if="videos">
                    <div class="video-list-part" v-for="video in videos.data" :key="video.id">
                        <router-link :to="'/video/detail/' + video.slug">
                            <div class="video-card">
                                <div class="video-card-image">
                                    <img :src="video.thumbnail" alt="rumah sakit melinda 2" />
                                </div>
                                <div class="video-card-text">
                                    <div class="video-card-title">
                                        <h3>{{ wordsLimit(video.name, 35) }}</h3>
                                    </div>
                                    <div class="video-card-description">
                                        <p>{{ wordsLimit(removeHTML(video.description), 50) }}</p>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>
    import { computed } from "vue";
    import { useVideoStore } from "@/stores/video";
    import HeadingContent from "@/components/utils/HeadingContent.vue";
    function wordsLimit(words, max){
        if(words.length > max) words = words.substring(0, max) + '...';
        return words;
    }
    function removeHTML(html){
        var div = document.createElement("div");
        div.innerHTML = html;
        var text = div.textContent || div.innerText || "";
        return text;
    }
    const videos = computed(() => useVideoStore().videos);
    useVideoStore().setVideos();
</script>
<style scoped>
    section{
        display: flex;
        padding: 2rem;
        padding-left: 5%;
        padding-right: 5%;
        margin: auto;
        justify-content: center;
        width: 100%;
    }
    .section-inner{
        width: 100%;
    }
    .section-head{
        padding: 0.5rem;
    }
    .video-list{
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        overflow: auto;
    }
    .video-list-part{
        min-width: 450px;
        width: 25%;
        padding: 0.5rem;
        transition: 0.2s;
    }
    .video-card{
        position: relative;
        overflow: hidden;
        height: 100%;
    }
    .video-card-image{
        width: 100%;
    }
    .video-card-image img{
        width: 100%;
    }
    .video-card-text{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0.5rem;
        background: rgba(0, 0, 0, 0.315);
        color: #ffffff;
    }
    .video-card-title h3{
        font-size: 1.1rem;
        font-weight: 700;
        margin: 0;
        margin-bottom: 1rem;
    }
    .video-card-description p{
        color: #ffffff;
        font-size: 1rem;
        margin: 0;
    }
    @media only screen and (max-width: 1500px){
        /*  */
    }
    @media only screen and (max-width: 1200px){
        .video-list-part{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 850px){
        .video-list-part{
            width: 50%;
        }
    }
    @media only screen and (max-width: 750px){
        .video-list{
            flex-wrap: wrap;
        }
        .video-list-part{
            min-width: unset;
            width: 100%;
        }
    }
</style>