<script>
    import FooterContent from "@/components/utils/FooterContent.vue"
    import HeroText from "@/components/utils/HeroText.vue"
    import HeadingContent from "@/components/utils/HeadingContent.vue"
    import axios from "axios"
    export default {
        data(){
            return {
                header: [],
                link: [],
            }
        },
        components: {
            FooterContent, HeroText, HeadingContent
        },
        mounted() {
            window.scrollTo(0, 0);
            axios.get('https://backend2.melindadev.com/api/page-header/link').then(
                response => this.header = response.data.page_header
            )
            axios.get('https://backend2.melindadev.com/api/link').then(
                response => this.link = response.data
            )
        }
    }
</script>

<template>    
    <HeroText blueTheme :subTitle="header.sub_title" :title="header.title" :content="header.description" />    
    <section class="mx-0 px-0 container-fluid row">
        <div class="col-sm-6 row mx-0 d-flex align-items-center bg-link" :style="{ backgroundImage: 'url(' + link.melinda2.img_url + ')' }" v-if="link.melinda2">
            <div class="col-sm-12">
                <HeadingContent 
                    :title="link.melinda2.name"
                    subTitle="Group of Melinda Hospital"
                    :paragraph="link.melinda2.description"
                    text_dark
                >
                    <a :href="link.melinda2.link" class="link-to-website btn btn-light text-white">Link To Website <i class="bi bi-arrow-right"></i></a>
                </HeadingContent>
            </div>
        </div>
        <div class="col-sm-6 row mx-0 d-flex align-items-center bg-link" :style="{ backgroundImage: 'url(' + link.melinda3.img_url + ')' }"  v-if="link.melinda3">
            <div class="col-sm-12">
                <HeadingContent 
                    :title="link.melinda3.name"
                    subTitle="Group of Melinda Hospital"
                    :paragraph="link.melinda3.description"
                    text_dark
                >
                    <a :href="link.melinda3.link" class="link-to-website btn btn-light text-white">Link To Website <i class="bi bi-arrow-right"></i></a>
                </HeadingContent>
            </div>
        </div>
    </section>
    <section class="mx-0 container-fluid row background-cream" id="link-other">
        <template v-for="li in link.list" :key="li.name">
            <div class="col-sm-4 link-icon">
                <div class="d-flex align-items-center gap-3">
                    <img :src="li.img_url" alt="first-aid" />
                    <h3 class="p-0 m-0 name-link align-self-center">{{ li.name }}</h3>
                </div>
                <p v-html="li.description"></p>
                <a :href="li.link" class="link-to-website btn btn-primary">Link to Website <i class="bi bi-arrow-right"></i></a>
            </div>
        </template>        
    </section>
    <FooterContent />
</template>

<style scoped>
    .col-sm-6 {
        height: 500px;
    }
    .name-link {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 42px;
        /* identical to box height */
        color: #1B4674;
    }
    .link-icon div img {
        width: 32px;
    }
    #link-other {
        padding: 160px 80px;
    }

    .link-to-website {
        border-radius: 40px;
        background-color: #75C6EF;
        padding: 8 16px 8 16px;
        font-size: 14px;
        border: none;
    }
    
    .link-to-website:hover {
        border-radius: 40px;
        background-color: #57add8;
        padding: 8 16px 8 16px;
        font-size: 14px;
        border: none;
    }

    .konten {
        padding: 48px 10% 48px 10%;     
    }

    .background-cream {
        background-color: #FDF4E9;
    }
    p.fw-light {
        line-height: 150%;
        color: #575757 !important;
    }
    #hero-images-homepage {
        width: 50%;    
        display: block;    
    }

    .wrapper-hero-img {
        margin-top: -30%;
        z-index: 998;
        display: flex;
        justify-content: end;    
    }

    .mouse {
        width: 34px;
    }
    
    .bg-link {        
        background-size: cover;
        object-fit: cover;       
        background: rgb(29,29,29);
        background: linear-gradient(0deg, rgba(29,29,29,0.8015581232492998) 0%, rgba(51,51,51,0.5802696078431373) 35%, rgba(170,170,170,0) 100%);     
    }

    .content-melinda-3 {
        padding: 120px 60px 120px 60px;
        background-color: #1B4674;        
    }

    .links-desc {
        width: 60%;
        margin: 0 auto;
    }

    .card-link {
        position: relative;
    }

    .card-link-body {
        position: absolute;
        bottom: 8px;
        left: 16px;
    }

    .card-link-body p {
        color: #575757;
        width: 100% !important;
        font-size: 20px;
        font-weight: bold !important;    
    }

    .links-hero { padding: 80px 0 80px 0; }

    @media (max-width: 575px) {
        .wrapper-hero-img {
            margin-top: 0;
            z-index: 0;
            display: none;
            justify-content: end;    
        }
        .links-desc {
            width: 90%;
            margin: 0 auto;
        }
        .bg-link-img-2 {        
            background-image: url("@/assets/images/bg-link-1.png");
            background-size: cover;
            object-fit: cover;    
            padding: 120px 0 120px 0px;
        }
        #link-other {
            padding: 80px 20px;
        }
    }

    @media (max-width: 1199.98px) {
        #link-other {
            padding: 80px 40px;
        }
        .wrapper-hero-img {
            margin-top: 0;
            z-index: 0;
            display: flex;
            justify-content: end;    
        }
        .links-desc {
            width: 80%;
            margin: 0 auto;
        }
        .bg-link-img-2 {        
            background-image: url("@/assets/images/bg-link-1.png");
            background-size: cover;
            object-fit: cover;    
            padding: 120px 0 120px 0px;
        }
    }
</style>