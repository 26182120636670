<script>
    import axios from "axios";
    import moment from "moment";
    import HeroText from "@/components/utils/HeroText.vue"
    import FooterContent from "@/components/utils/FooterContent.vue"
    export default {
        data() {
            return {
                context: {
                    subTitle: '',
                    title: '',
                    paragraph: ``
                },
                careerTags: [],
                careerTagSelected: 'Semua',
                search: '',
                val_search: '',
                careers: [],
                header : {},
            }
        },
        computed: {
            filteredCareers() {                
                if (this.search.length > 0) {                    
                    return this.careers.filter(r => {
                        return r.name.toLowerCase().includes(this.search.toLowerCase());
                    });
                }
                else if(this.careerTagSelected != "Semua"){
                    console.log(this.careerTagSelected)
                    return this.careers.filter(r => {
                        console.log(this.careerTagSelected);
                        return r.auction_type_id === this.careerTagSelected;
                    });
                }
                return this.careers
            }
        },
        methods: {
            goToDetail() {
                this.$router.push('/autcion-jobs-detail');
            },
            format_date(value){
                if (value) {
                    return moment(String(value)).format('DD MMMM YYYY')
                }
            },
            searchButtonHandle() {
                this.search = this.val_search;
            }
        },
        components: {
            HeroText, FooterContent
        },
        mounted() {
            axios.get('https://backend2.melindadev.com/api/auction')
            .then(response => {
                this.careers = response.data.auctions;
                this.careerTags = response.data.types;
            })
            
            axios.get('https://backend2.melindadev.com/api/page-header/auction')
            .then(response => {
                this.header = response.data.page_header;
            });
        },
    }
</script>

<template>    
    <div class="container-fluid px-0">
        <div class="container-fluid mx-auto bg-blue py-4">
            <HeroText 
                blueTheme
                :subTitle="header.sub_title ? header.sub_title : context.subTitle" 
                :title="header.title ? header.title : context.title" 
                :content="header.description ? header.description : context.paragraph" 
            />
            <!-- input group -->
            <div class="input-group mb-5 container">
                <select class="form-select" aria-label="Default select example" name="careerTagSelected" v-model="careerTagSelected">
                    <option value="Semua">Semua</option>
                    <option v-for="(careerTag) in careerTags" :key="careerTag" v-bind:value="careerTag.auction_type_id">
                        {{careerTag.name}}
                    </option>                
                </select>
                <input v-model="val_search" type="text" class="form-control" id="basic-url" aria-describedby="basic-addon3" placeholder="Cari disini...">
                <button type="button" class="btn btn-primary py-3 btn-search" @click="searchButtonHandle">Search Jobs</button>            
            </div>
        </div>
        <!-- data careers -->
        <div class="container mx-auto px-0 gap-4 py-4">
            <div class="row"> 
                <div class="col-sm-12">
                    <div class="row mb-4 px-0"> 
                        <div class="col-sm-3" v-for="career in filteredCareers" :key="career.id">
                            <div class="card mb-4">                            
                                <div class="card-body d-flex flex-column justify-content-center">
                                    <span class="date flex-grow-1 text-decoration-none">{{ format_date(career.created_at) }}</span>
                                    <a :href="'/auction-jobs-detail/'+career.auction_id" class="btn btn-primary stretched-link">Go somewhere</a>
                                    <h4 class="card-title text-dark">{{career.name}}</h4>
                                    <h5 class="tags" v-if="career.type.name">{{career.type.name}}</h5>
                                    <span class="status">
                                        {{ parseInt(career.footer).toLocaleString('id-ID', {style: 'currency', currency: 'IDR', })  }}
                                    </span>                        
                                </div>                            
                            </div>                            
                        </div> 
                    </div>                    
                </div>
            </div>
        </div>
    </div>
    <FooterContent />
</template>


<style scoped>
    .btn.btn-search {
        background: #75C6EF;
        border: transparent;
    }
    .btn.btn-search:hover {
        background: #75C6EF;
        border: transparent;
        opacity: 0.5;
    }
    .bg-blue {
        background-color: #1B4674;
    }
    .card {
        height: 280px;
    }
    h4.card-title {        
        color: #1B4674;
    }
    h5.tags {
        color: #75C6EF;
        font-size: 14px;
    }
    span.status {
        margin-top: 16px;
    }
    span, p {
        color: #575757;
    }
    a.stretched-link {
        opacity: 0;
    }
    .card:hover {
        background-color: #1B4674;
    }
    .card:hover .date {
        color: #ffffff;
    }
    .card:hover h4.card-title {
        color: #ffffff !important;
    }
    .card:hover .status {
        color: #ffffff;
    }
    /* .card:hover .date{
        height: 280px;
        background: #1B4674;
        color: white;
    } */
</style>