<script>
    import HeadingContent from "@/components/utils/HeadingContent.vue";    
    export default {
        components: {
            HeadingContent
        },
        props: {
            data_article: Array,
            contact: Array,
        }
    }
</script>

<template>    
    <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
            <template v-for="(da, index) in data_article" :key="da.carousel_home_id.trim()">
                <div class="carousel-item" :class="{'active': index == 0}">
                    <div class="row">
                        <div class="col-sm-6 px-5 py-md-0 d-flex align-items-center flex-column justify-content-center" style="min-height: 500px">
                            <HeadingContent 
                                :subTitle="da.subtitle"
                                :title="da.title"
                                :paragraph="da.description.slice(0, 300) + '...'"
                                white_title
                            >
                                <!-- <a :href="'/artikel/'+da.carousel_home_id.trim()" class="align-self-start btn-readmore btn btn-primary rounded-pill">
                                    Read More
                                </a> -->
                            </HeadingContent>
                        </div>
                        <div class="d-none d-md-block col-sm-6 p-0 position-relative">
                            <template v-if="da.img">
                                <img :src="da.img" class="img-carousel card-img" :alt="da.title">
                            </template>
                            <template v-else>
                                <img :src="da.img_url.split('https://backend2.melindadev.com/images/article/').pop()" class="img-carousel card-img" :alt="da.title">
                            </template>
                            <div class="position-absolute bottom-0 end-0 ps-0 pe-2 col-sm-12 d-flex justify-content-end">
                                <button class="btn-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>       
                                <button class="btn-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>         
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
    <!-- slider controller mobile -->    
    <div class="ps-0 pe-2 col-sm-12 d-flex justify-content-end d-block d-md-none background-blue">
        <button class="btn-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>       
        <button class="btn-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>         
    </div>
    <div class="container-fluid row px-0 pb-4 pt-0 m-0" style="background-color: rgb(27, 70, 116);">
        <div class="col-sm-12 px-4 py-4 gap-4 px-md-0 m-0 d-flex flex-column flex-md-row justify-content-around controller-info">
            <div class="text-white">
                <div class="align-self-center d-flex align-items-center gap-4 text-white">
                    <i class="bi bi-telephone fs-3"></i>
                    <div class="d-flex flex-column">
                        <p class="fw-bold text-white m-0">Hotline & Emergency Case</p>
                        <p class="fw-light text-white m-0">{{contact.phone_number}}</p>
                    </div>
                </div>
            </div>
            <div class="text-white">
                <div class="align-self-center d-flex align-items-center gap-4 text-white">
                    <i class="bi bi-clock fs-3"></i>
                    <div class="d-flex flex-column">
                        <p class="fw-bold text-white m-0">Opening Hours</p>
                        <p class="fw-light text-white m-0">24 Hours</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .img-carousel {
        background-size: cover;
        object-fit: cover;        
        height: 90vh;
    }
    .controller-info {
        background-color:#1B4674;
    }
    .btn-pendaftaran {
        background: #75C6EF;
        border: transparent;       
    }
    .btn-pendaftaran:hover {
        background: #75C6EF;
        border: transparent;
        opacity: 0.5;       
    }
    .btn-control-next {
        background: #75C6EF;
        border: transparent;
        color: white;
    }
    .btn-control-next:hover {
        background: #75C6EF;
        border: transparent;
        color: white;
        opacity: 0.8;
    }
    .btn-control-prev {
        background: #75c6ef7a;
        border: transparent;
        color: white;
    }
    .btn-control-prev:hover {
        background: #75C6EF;
        border: transparent;
        color: white;
        opacity: 0.8;
    }
    #carouselExampleControls  {
        /* z-index: -999; */
    }
    .controller-slider .col-sm-8 {        
        /* z-index: 1000; */
        /* margin-top: -185px; */
    }
    .btn-readmore {
        color: #575757;
        background-color: #FCE8CE;
        border: transparent;
    }
    .btn-readmore:hover {
        color: #575757;
        background-color: #FCE8CE;
        border: transparent;
        opacity: 0.8;
    }
    .carousel-item .row {
        background-color:#1B4674;
    }
</style>