import { defineStore } from "pinia";
import axiosClient from "../axios.js";

export const useArticleStore = defineStore("article", {
    state: () => ({
        // ==================== states used on artikel page and its relation
        homeArticles: null,
        articles: null
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on artikel page and its relation
        async setHomeComponents(){
            const response = await axiosClient.get(`https://backend.hctesting.site/api/v1/article/content`);
            this.homeArticles = response.data.data.slice(0, 3);
            console.log(this.homeArticles);
        },
        async setArticles(){
            const response = await axiosClient.get(`https://backend1.melindadev.com/api/article`);
            // this.articles = response.data.articles;
            console.log(response);
        }
    }
});