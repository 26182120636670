import "bootstrap/dist/css/bootstrap.min.css"
// import "@morioh/v-lightbox/dist/lightbox.css"
import { createApp  } from 'vue'
import { createPinia } from 'pinia'
// import Lightbox from '@morioh/v-lightbox'
import App from './App.vue'
import router from './router'
import { createHead } from '@vueuse/head'
const head = createHead()
// global register
createApp(App).use(createPinia()).use(router).use(head).mount('#app')
import "bootstrap/dist/js/bootstrap.js"


window.onscroll = function(){
    const gotop = document.getElementsByClassName('btn-up-scroll'); // <================== scroll top icon
    const ltree = document.querySelector('.link-tree-icon');
    var i;
    if(document.body.scrollTop > 100 || document.documentElement.scrollTop > 100){
        for(i = 0; i < gotop.length; i++){
            if(screen.width > 1500){
                gotop[i].style.bottom = '2.5%';
            }else if(screen.width > 750){
                gotop[i].style.bottom = '2.5%';
            }else{
                gotop[i].style.bottom = '2.5%';
            }
        }
        ltree.children[0].children[0].style.transform = 'rotate(-360deg)';
        ltree.style.right = '115px';
    }else{
        for(i = 0; i < gotop.length; i++){
            gotop[i].style.bottom = '-15%';
        }
        ltree.children[0].children[0].style.transform = 'rotate(0)';
        ltree.style.right = '50px';
    }
}
// ==================================================== when window is clicked starts
window.onclick = function(e){
    const rotatable = document.getElementsByClassName('rotatable-arrow');
    const floatel = document.getElementsByClassName('floating-element');
    var i;
    if(!e.target.classList.contains('rotatable-arrow-related')){
        for(i = 0; i < rotatable.length; i++){
            rotatable[i].style.transform = 'rotate(0)';
        }
        for(i = 0; i < floatel.length; i++){
            floatel[i].style.display = 'none';
        }
    }else{
        // for(i = 0; i < rotatable.length; i++){
        //     rotatable[i].style.transform = 'rotate(0)';
        // }
    }
    // alert(i);
}
// ==================================================== when window is clicked ends