<template>
    <VideoHeaderComponent/>
    <VideoCardComponent/>
    <FooterContent/>
</template>
<script setup>
    import VideoHeaderComponent from '@/components/video/VideoHeaderComponent.vue';
    import VideoCardComponent from '@/components/video/VideoCardComponent.vue';
    import FooterContent from '@/components/utils/FooterContent.vue';
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
</script>
<style>
    /*  */
</style>