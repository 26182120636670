<script>
import axios from "axios";
export default {
  data() {
    return {
      //state loggedIn with localStorage
      loggedIn: localStorage.getItem("loggedIn"),
      //state token
      token: localStorage.getItem("token"),
      //state user
      user: [],
      //state validation
      validation: [],
      //state login failed
      loginFailed: null,
      // set tabs aktif
      isSignupActive: true,
      isLoginActive: false,
      layout: {},
      contact: {},
      listcontact: {},
      socials: {},
      specialities: [],
      doctors: [],
      schedule: [],
      doctorSchedule: [],
      // doctors: [],
      splitCategory: [],
      signup: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        password_confirmation: null,
      },
      registration: {
        doctor_speciality_id: null,
        poli_selection: null,
        doctor: null,
        doctor_id: null,
        full_name: null,
        phone: null,
        time: null,
        is_member: "No",
      },
      fs_contact_name: null,
      fs_img_url: null,
    };
  },
  computed: {
    getCategorySplited() {
      return this.splitCategory[1];
    },
  },
  methods: {
    changeToLoginActive() {
      this.isSignupActive = true;
      this.isLoginActive = false;
    },
    changeToSignupActive() {
      this.isSignupActive = false;
      this.isLoginActive = true;
    },
    changeSpeciality(event) {
      let id = event.srcElement.value;
      let splitedId = id.split(",");
      this.splitCategory = splitedId;
      console.log(splitedId);
      axios
        .get("https://backend2.melindadev.com/api/speciality/" + splitedId[0])
        .then((response) => {
          this.doctors = response.data.doctors;
        });
    },
    changeDoctor(ev) {
      let doctorid = ev.target.value;
      let doctor = this.doctors.filter((el) => {
        return el.doctor_id == doctorid;
      });

      this.registration.doctor = doctor[0].name;

      axios
        .get("https://backend2.melindadev.com/api/schedule/" + doctorid)
        .then((res) => {
          this.doctorSchedule = res.data;
        });
    },
    submitRegistration: function () {
      // axios.post('https://backend2.melindadev.com/api/registration', this.registration)
      // .then((res) => {
      //     console.log(res);
      // })
      // .catch((error) => {
      //     console.log(error);
      // })
      var text = 'Halo, saya ingin mendaftar atas nama ' + this.registration.full_name + ' untuk berkonsultasi dengan dokter ' + this.registration.doctor + ' di poli ' + this.splitCategory[1] + ' pada ' + this.registration.time + '. Jika tidak bisa pada waktu tersebut bisa diinfokan untuk waktu yang lain?';
      window
        .open(
          `https://wa.me/628157100888?text=` + text.replace('&', 'dan'),
          "_blank"
        )
        .focus();
      // window
      //   .open(
      //     `https://wa.me/628157100888?text=Halo%2C%20saya%20ingin%20mendaftar%20atas%20nama%20${this.registration.full_name}%20untuk%20berkonsultasi%20dengan%20dokter%20${this.registration.doctor}%20di%20poli%20${this.splitCategory[1]}%20pada%20${this.registration.time}.%20jika%20tidak%20bisa%20pada%20waktu%20tersebut%20bisa%20diinfokan%20untuk%20waktu%20yang%20lain%3F`,
      //     "_blank"
      //   )
      //   .focus();
        // console.log(this.registration);
        // console.log(this.splitCategory);
    },
    submitSignUp: function () {
      if (this.signup.password) {
        this.signup.password_confirmation = this.signup.password;
      }
      axios
        .post("https://backend2.melindadev.com/api/register", this.signup)
        .then((res) => {
          console.log(res);
          alert("Account created successfully");
        })
        .catch((error) => {
          console.log(error);
        });

      this.validation = [];
      if (!this.signup.first_name) {
        this.validation.first_name = true;
      }
      if (!this.signup.last_name) {
        this.validation.last_name = true;
      }
      if (!this.signup.email) {
        this.validation.email = true;
      }
      if (!this.signup.password) {
        this.validation.password = true;
      }
    },
    login() {
      this.loginFailed = false;
      if (this.user.email && this.user.password) {
        axios
          .get("https://backend2.melindadev.com/sanctum/csrf-cookie")
          .then((response) => {
            //debug cookie
            console.log(response);
            axios
              .post("https://backend2.melindadev.com/api/login", {
                email: this.user.email,
                password: this.user.password,
              })
              .then((res) => {
                //debug user login
                console.log(res);
                //set localStorage
                localStorage.setItem("loggedIn", "true");
                //set localStorage Token
                localStorage.setItem("token", res.data.bearer_token);
                //change state
                this.loggedIn = true;

                alert("Sucessfully logged in");

                //redirect profile
                window.location.href = "/dashboard";
                // window.location.reload();
                // this.$router.push({name:'dashboard'});
                // return this.$router.push({ name: 'profile',params:{username:this.user.email} })
              })
              .catch((error) => {
                console.log(error);
                this.loginFailed = true;
              });
          });
      }

      this.validation = [];
      if (!this.user.email) {
        this.validation.email = true;
      }
      if (!this.user.password) {
        this.validation.password = true;
      }
    },
    logout() {
      if (confirm("Are you sure?")) {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        axios
          .delete("https://backend2.melindadev.com/api/logout", {
            headers: headers,
          })
          .then(() => {
            //remove localStorage
            localStorage.removeItem("loggedIn");
            localStorage.removeItem("token");
            //redirect
            // window.location.reload();
            // this.$router.push({ name: 'home' })
            alert("Sucessfully logged out");

            window.location.href = "/";
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    axios.get("https://backend2.melindadev.com/api/layout").then((response) => {
      this.layout = response.data;
      this.contact = response.data.contact;
      this.socials = response.data.social_media;
    });
    axios
      .get("https://backend2.melindadev.com/api/speciality")
      .then((response) => {
        this.specialities = response.data.specialities;
      });
    axios
      .get("https://backend2.melindadev.com/api/contact-us")
      .then((response) => {
        this.listcontact = response.data.contact_us;
      });
    axios
      .get("https://backend2.melindadev.com/api/footer_service")
      .then((response) => {
        this.fs_contact_name = response.data.data.name;
        this.fs_img_url = response.data.data.img_url;
      });
  },
};
</script>

<template>
  <!-- footer -->
  <footer>
    <!-- contact -->
    <div id="contact" class="row mx-0">
      <div id="desc" class="col-sm-8 d-flex flex-column flex-xl-row">
        <div class="photo-contact">
          <img
            src="@/assets/images/contact.png"
            alt="contact"
            id="contact-img"
          />
          <img :src="fs_img_url" alt="person" id="person-img" />
        </div>
        <p class="mt-4 mt-md-0">
          {{ fs_contact_name }} {{ contact.phone_number }}
        </p>
      </div>
      <span
        id="contacts"
        class="col-sm-4 mt-4 mt-xl-0 d-flex align-items-center gap-3 justify-content-center"
      >
        <template v-for="social in socials" :key="social.social_media_id">
          <a :href="social.link" target="_blank">
            <img
              :src="social.logo_url"
              class="logo-social img-fluid"
              alt="logo-footer"
            />
          </a>
        </template>
        <a
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#auth"
          class="link-to-website btn btn-primary"
          >Pendaftaran <i class="bi bi-arrow-right"></i
        ></a>
      </span>
    </div>
    <!-- footer content -->
    <div
      class="footer-konten container-fluid konten py-5 row gap-5 gap-md-0 mx-0 my-4"
    >
      <div class="col-sm-6">
        <img
          v-if="!layout.logo_footer"
          src="@/assets/images/logo.png"
          class="logo-footer img-fluid"
          alt="logo-footer"
        />
        <img
          v-else
          :src="layout.logo_footer"
          class="logo-footer img-fluid"
          alt="logo-footer"
        />
      </div>
      <div class="col-sm-3 footer-konten-text">
        <span class="footer-title">Address</span>
        <div class="my-2"></div>
        <p>{{ contact.address }}</p>
      </div>
      <div class="col-sm-3 footer-konten-text">
        <span class="footer-title">Contact</span>
        <div class="my-2"></div>
        <div class="d-flex flex-column">
          <p class="d-flex flex-colum flex-md-row gap-2">
            <span v-html="listcontact.contact" class="contact-text"></span>
          </p>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-column flex-lg-row justify-content-between align-items-center konten copyright"
    >
      <p>{{ layout.reserved }}</p>
      <div class="d-flex gap-4 align-items-center">
        <img :src="layout.logo_province" class="copyright-logos img-fluid" />
        <img :src="layout.logo_muri" class="copyright-logos img-fluid" />
        <a href="#" class="copyright-link">Privacy & Policy</a>
        <a href="#" class="copyright-link">Cookies</a>
        <a
          v-if="!loggedIn"
          href="#"
          class="copyright-link"
          data-bs-toggle="modal"
          data-bs-target="#login-register"
          >Login</a
        >
        <a v-if="loggedIn" href="/dashboard" class="copyright-link"
          >Dashboard</a
        >
        <a
          v-if="loggedIn"
          href="javascript:void(0);"
          class="copyright-link"
          @click="logout"
          >Logout</a
        >
      </div>
    </div>
  </footer>
  <!-- MODAL DAFTAR -->
  <!-- Modal -->
  <div
    class="modal fade"
    id="auth"
    tabindex="-1"
    aria-labelledby="authLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="d-flex flex-column">
            <h5 class="modal-title" id="authLabel">
              Form Pendaftaran Rawat Jalan
            </h5>
            <h2>Form Pendaftaran</h2>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <form action="">
          <div class="modal-body border-0">
            <div class="mb-2">
              <label for="exampleInputEmail1" class="form-label"
                >Pilih Poli</label
              >
              {{ poli_selection }}
              <select
                @change="changeSpeciality"
                class="form-select"
                v-model="registration.poli_selection"
                required
              >
                <option :value="null">Pilih Poli</option>
                <template
                  v-for="speciality in specialities"
                  :key="speciality.name"
                >
                  <option
                    :value="[speciality.doctor_speciality_id, speciality.name]"
                  >
                    {{ speciality.name }}
                  </option>
                </template>
              </select>
            </div>
            <div class="mb-2" v-if="registration.poli_selection">
              {{ registration.doctor_selection }}
              <label for="exampleInputEmail1" class="form-label"
                >Pilih Dokter</label
              >
              <select
                class="form-select"
                @change="changeDoctor"
                v-model="registration.doctor_id"
                required
              >
              <option :value="null">Pilih Dokter</option>
                <template v-for="doctor in doctors" :key="doctor.doctor_id">
                  <option :value="doctor.doctor_id">
                    {{ doctor.name }}
                  </option>
                </template>
              </select>
            </div>
            <div class="mb-2" v-if="registration.doctor_id">
              <label for="exampleInputEmail1" class="form-label"
                >Pilih Jam Praktek</label
              >
              <select class="form-select" v-model="registration.time" required>
                <!-- <option value="06:00 - 06:30">06:00 - 07:00</option>
                <option value="06:30 - 07:00">06:30 - 07:00</option>
                <option value="07:00 - 07:30">07:00 - 07:30</option> -->
                <option :value="null">Pilih Jam Praktek</option>
                <option
                  v-for="schedule in doctorSchedule"
                  :key="schedule"
                  :value="
                    `hari ${schedule.day}` +
                    (schedule.time_start
                      ? ` pukul ${schedule.time_start} - ${schedule.time_end}`
                      : '')
                  "
                >
                  {{ schedule.day }}
                  <span v-if="schedule.time_end">
                    {{ `${schedule.time_start} - ${schedule.time_end}` }}
                  </span>
                </option>
              </select>
            </div>
            <div class="mb-2">
              <label for="exampleInputEmail1" class="form-label"
                >Nama Pasien</label
              >
              <input
                type="text"
                class="form-control"
                v-model="registration.full_name"
                placeholder="masukkan nama pasien"
                required
              />
            </div>
            <div class="mb-2">
              <label for="exampleInputEmail1" class="form-label"
                >Phone Number</label
              >
              <input
                type="number"
                class="form-control"
                v-model="registration.phone"
                placeholder="masukkan nomor telepon"
                required
              />
            </div>
            <!-- <div class="mb-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  v-model="registration.is_member"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value="Yes"
                  required
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Member Melinda Hospital
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  v-model="registration.is_member"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="No"
                  required
                  checked
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  Non Member Melinda Hospital
                </label>
              </div>
            </div> -->
          </div>
        </form>
        <div class="modal-footer d-flex justify-content-between">
          <button
            type="button"
            class="btn btn-secondary btn-batal"
            data-bs-dismiss="modal"
          >
            Batal
          </button>
          <button
            @click="submitRegistration"
            class="btn btn-primary btn-daftar"
            :disabled="
              !splitCategory ||
              !registration.doctor_id ||
              !registration.full_name ||
              !registration.phone ||
              !registration.is_member
            "
            data-bs-toggle="modal"
            data-bs-target="#berhasilDaftar"
          >
            Daftar Sekarang <i class="bi bi-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL KONFIRMASI BERHASIL DAFTAR -->
  <!-- Modal -->
  <div
    class="modal fade my-auto align-self-center"
    id="berhasilDaftar"
    tabindex="-1"
    aria-labelledby="berhasilDaftarLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog py-5 my-5">
      <div class="modal-content">
        <div class="modal-header">
          <div class="d-flex flex-column">
            <h5 class="modal-title" id="authLabel">
              Form Pendaftaran Rawat Jalan
            </h5>
            <h2>Form Pendaftaran</h2>
            <p>
              Terima kasih telah melakukan pendaftaran melalui website kami,
              data anda akan kami proses dan akan kami akan mengirimkan
              konfirmasi melalaui SMS/Telpon.
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary btn-daftar"
            data-bs-dismiss="modal"
            data-bs-target="#berhasilDaftar"
          >
            Tutup <i class="bi bi-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Login & regiser -->
  <div
    class="modal fade"
    id="login-register"
    tabindex="-1"
    aria-labelledby="login-register"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content container">
        <div class="modal-header row p-0 border-0">
          <div
            class="col-6 px-0 py-4 d-flex justify-content-center tab-auth"
            :class="{ active: isSignupActive }"
            @click="changeToLoginActive"
          >
            Sign Up
          </div>
          <div
            class="col-6 px-0 py-4 d-flex justify-content-center tab-auth"
            :class="{ active: isLoginActive }"
            @click="changeToSignupActive"
          >
            Login
          </div>
        </div>
        <div class="modal-body">
          <div class="container-fluid tab-auth-body" v-if="isSignupActive">
            <h1 class="fs-3">Sign Up</h1>
            <!-- <div class="d-grid gap-3">
                            <button class="btn btn-primary py-2" type="button"><i class="bi bi-facebook"></i> Login With Facebook</button>
                            <button class="btn btn-outline-primary py-2" type="button"><i class="bi bi-google"></i> Login With Google</button>
                            <p class="form-text text-center">Or sign up using email</p>
                        </div> -->
            <form @submit.prevent="submitSignUp">
              <div class="mb-2">
                <label class="form-label">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="signup.first_name"
                />
                <div
                  v-if="validation.first_name"
                  class="invalid-feedback d-block"
                >
                  Enter First Name
                </div>
              </div>
              <div class="mb-2">
                <label class="form-label">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="signup.last_name"
                />
                <div
                  v-if="validation.last_name"
                  class="invalid-feedback d-block"
                >
                  Enter Last Name
                </div>
              </div>
              <div class="mb-2">
                <label class="form-label">Email Address</label>
                <input
                  type="email"
                  class="form-control"
                  v-model="signup.email"
                />
                <div v-if="validation.email" class="invalid-feedback d-block">
                  Enter Email
                </div>
              </div>
              <div class="mb-2">
                <label class="form-label">Password</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="signup.password"
                />
                <div v-if="validation.email" class="invalid-feedback d-block">
                  Enter Email
                </div>
              </div>
              <div class="d-grid">
                <button class="btn btn-primary rounded-pill py-2" type="submit">
                  Sign Up
                </button>
              </div>
              <p class="form-text text-center pb-4">
                By signing up, I agree to RS Melinda Terms of Service and
                Privacy Policy.
              </p>
            </form>
          </div>
          <div class="container-fluid tab-auth-body" v-if="isLoginActive">
            <h1 class="fs-3">Login</h1>
            <!-- <div class="d-grid gap-3">
                            <button class="btn btn-primary py-2" type="button"><i class="bi bi-facebook"></i> Login With Facebook</button>
                            <button class="btn btn-outline-primary py-2" type="button"><i class="bi bi-google"></i> Login With Google</button>
                            <p class="form-text text-center">Or sign up using email</p>
                        </div> -->
            <form @submit.prevent="login">
              <div v-if="loginFailed" class="alert alert-danger">
                Email or Password Incorrect.
              </div>
              <div class="mb-2">
                <label class="form-label">Email Address</label>
                <input
                  type="email"
                  name="email"
                  v-model="user.email"
                  class="form-control"
                />
                <div v-if="validation.email" class="invalid-feedback d-block">
                  Enter Email
                </div>
              </div>
              <div class="mb-2">
                <label class="form-label">Password</label>
                <input
                  type="password"
                  name="password"
                  v-model="user.password"
                  class="form-control"
                />
                <div
                  v-if="validation.password"
                  class="invalid-feedback d-block"
                >
                  Enter Password
                </div>
              </div>
              <div class="d-grid">
                <button class="btn btn-primary rounded-pill py-2" type="submit">
                  Login
                </button>
              </div>
            </form>
            <a
              class="form-text mc-auto pb-4"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#forgot-password"
              >Forgot Password?</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Forgot Password -->
  <div
    class="modal fade"
    id="forgot-password"
    tabindex="-1"
    aria-labelledby="login-register"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content container">
        <div class="modal-body">
          <div class="container-fluid tab-auth-body">
            <h1 class="fs-3">
              We’ll send you an email to reset your password.
            </h1>
            <form>
              <div class="mb-2">
                <label class="form-label">Email Address</label>
                <input type="email" class="form-control" />
              </div>
              <div class="d-grid">
                <button class="btn btn-primary rounded-pill py-2" type="button">
                  Reset Password
                </button>
              </div>
              <p class="form-text text-center pb-4">
                Don't Have a Account?
                <a
                  class="form-text text-center pb-4"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#login-register"
                >
                  Sign Up
                </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.contact-text p {
  color: #1c4576 !important;
}
.logo-social {
  width: 32px;
  background-size: cover;
  object-fit: cover;
}
.tab-auth-body .d-grid .btn.btn-primary {
  background-color: #3b5998;
  border: #3b5998;
}
.tab-auth-body .d-grid .btn.btn-primary:hover {
  background-color: #2a4683;
  border: #3b5998;
}
.tab-auth-body .d-grid .btn.btn-outline-primary {
  background-color: #ffffff;
  border: 1px solid #3b5998;
  color: #3b5998;
}
.tab-auth-body .d-grid .btn.btn-outline-primary:hover {
  background-color: #3b5998;
  border: 1px solid transparent;
  color: #ffffff;
}
.tab-auth-body h1 {
  font-weight: 700;
  color: #1b4674;
}
.tab-auth {
  font-weight: 700;
  color: #575757;
  background-color: #f8f8f8;
  cursor: pointer;
}
.tab-auth:hover {
  font-weight: 700;
  color: #575757;
  background-color: #f8f8f8;
  cursor: pointer;
  opacity: 0.8;
}
.tab-auth.active {
  font-weight: 700;
  color: #1b4674;
  background-color: #ffffff;
  cursor: pointer;
}
.btn-primary {
  background-color: #75c6ef !important;
  border-radius: 40px;
  border: 1px solid transparent;
}
.btn-primary:hover {
  background: #1b4674;
  border-radius: 40px;
  opacity: 0.5;
}
.btn-batal {
  color: #474747;
  background-color: transparent;
  border: transparent;
}
.btn-batal:hover {
  color: #474747;
  background-color: transparent;
  border: transparent;
  opacity: 0.5;
}
.modal-header div h5 {
  font-size: 14px;
  color: #75c6ef !important;
}
.modal-header div h2 {
  font-size: 24px;
  color: #1b4674;
  font-weight: bold;
}
footer #contact {
  width: 100%;
  padding: 48px 10% 48px 10%;
  justify-content: space-between;
  background-color: #fef8f0;
}

footer .footer-konten {
  background-color: #ffffff !important;
}

.logo-footer {
  max-width: 160px;
  object-fit: cover;
  background-size: cover;
}

.footer-konten-text .footer-title {
  font-size: 18px;
  color: #1c4576;
  font-weight: bolder;
}

#contact #desc {
  display: flex;
  justify-content: center;
  align-items: center;
}
#contact #desc p {
  color: #1b4674;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0 !important;
}

#contact #desc .photo-contact {
  display: flex;
}
#contact #desc .photo-contact img {
  width: 40px;
}
#contact #desc .photo-contact #contact-img {
  z-index: 997 !important;
}
#contact #desc .photo-contact #person-img {
  margin-left: -10px;
  margin-right: 20px;
}

span#contacts .bi-facebook {
  color: #4267b2;
}
span#contacts .bi-twitter {
  color: #1da1f2;
}
span#contacts .bi-linkedin {
  color: #0072b1;
}
span#contacts .bi-instagram {
  color: #bc2a8d;
}

.footer-konten-text p,
.menu-map {
  color: #1c4576;
  font-weight: 100 !important;
  font-size: 16px;
  text-decoration: none;
}
footer hr {
  width: 80%;
}
footer .footer-konten {
  background-color: #ffffff !important;
}
.footer-konten-text .footer-title {
  font-size: 18px;
  color: #1c4576;
  font-weight: bolder;
}

.footer-konten-text p,
.menu-map {
  color: #1c4576;
  font-weight: 100 !important;
  font-size: 16px;
  text-decoration: none;
}

.konten {
  padding: 48px 10% 48px 10%;
  background-color: #1b4674;
}
.copyright.konten {
  background-color: #ffffff00;
  background-image: url("@/assets/images/batik.png");
  background-size: cover;
}

.copyright p {
  color: #1c4677;
  font-size: 14px;
}

a.copyright-link {
  color: #1c4677 !important;
  font-size: 14px !important;
  text-decoration: none;
}

.copyright-logos {
  max-width: 40px;
}
@media (max-width: 575px) {
  footer #contact {
    width: 100%;
    padding: 48px 5% 48px 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fef8f0;
  }
  footer hr {
    width: 90%;
  }
}
</style>
