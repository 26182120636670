<script>
    import HeroText from "@/components/utils/HeroText.vue";
    import FooterContent from "@/components/utils/FooterContent.vue"
    import axios from "axios";
    import { timeFormat } from "@/helpers/functions";
    export default {
        components: {
            HeroText, FooterContent
        },
        data() {

            return {
                // data:[],
                filterSpecialist: 'Semua',
                search: '',
                val_search: '',        
                doctors: [],                                       
                header : {},
                detail_doctor : [],                    
            }
        },
        mounted() {
            window.scrollTo(0, 0);
            axios.get('https://backend2.melindadev.com/api/doctor').then(
                response => (this.doctors = response.data.doctors),
                response => (this.filterSpecialist = response.data.filterSpecialist),
                response => (this.search = response.data.search),
            ),    
            axios.get('https://backend2.melindadev.com/api/page-header/doctor')
            .then(response => {
                this.header = response.data.page_header;
            });            
        },
        methods: {
            getDetailDoctor(id) {
                // console.log(`Doctor ${id}`);
                // Request API
                axios.get(`https://backend2.melindadev.com/api/doctor/${id}`).then(
                    response => this.detail_doctor = response.data.doctor,                    
                );
                console.log(this.detail_doctor_speciality);
            },
            searchButtonHandle() {
                this.search = this.val_search;
            },
            timeFormat(time){
                return timeFormat(time);
            }
        }
        ,
        computed: {
            filteredDoctors() {
                if (this.filterSpecialist != 'Semua') {
                    return (
                        this.doctors.filter(result => result.specialist === this.filterSpecialist)
                    )
                } else {
                    return this.doctors
                }                
            },                
        },
    }
</script>

<template>
    <div class="container-fluid" style="background-color: #1B4674">
        <div class="container mx-auto pb-5">
            <HeroText 
                blueTheme
                :subTitle="header.sub_title ? header.sub_title : ''"
                :title="header.title ? header.title : ''"
                :content="header.description ? header.description : ''"
            />
            <!-- input group -->
            <div class="d-none d-md-block container mx-auto" style="margin-bottom: -20px;">
                <div class="input-group">
                    <select class="form-select" aria-label="Default select example" name="filterSpecialist" v-model="filterSpecialist">
                        <option value="Semua" selected>Semua</option>
                        <template v-for="doctor in doctors" :key="doctor.specialist">
                            <option v-bind:value="doctor.specialist" v-if="doctor.dataDoctor.length > 0">
                                {{doctor.specialist}}
                            </option>
                        </template>
                    </select>
                    <input               
                        type="text" 
                        class="form-control" 
                        id="basic-url" 
                        aria-describedby="basic-addon3" 
                        placeholder="Cari disini..."
                        v-model="val_search"                
                    >
                    <button class="btn btn-outline-primary py-3" type="button" id="button-addon2"
                        @click="() => {searchButtonHandle()}"
                    >
                        Search Doctor dan Schedule
                    </button>        
                </div>
            </div>
            <!-- input group mobile -->
            <div class="d-block d-md-none d-flex flex-column gap-2 container">
                <select class="form-select" aria-label="Default select example" name="filterSpecialist" v-model="filterSpecialist">
                    <option value="Semua" selected>Semua</option>
                    <template v-for="doctor in doctors" :key="doctor.specialist">
                        <option v-bind:value="doctor.specialist" v-if="doctor.dataDoctor.length > 0">
                            {{doctor.specialist}}
                        </option>
                    </template>
                </select>
                <input               
                    type="text" 
                    class="form-control" 
                    id="basic-url" 
                    aria-describedby="basic-addon3" 
                    placeholder="Cari disini..."
                    v-model="val_search"                
                >
                <button class="btn btn-outline-primary py-3" type="button" id="button-addon2"
                    @click="() => {searchButtonHandle()}"
                >
                    Search Doctor dan Schedule
                </button>
            </div>
        </div>
    </div>
    <div class="container mx-auto">
         <!-- data dokter -->        
        <div class="container-fluid px-0 gap-4">
            <template v-for="doctor in filteredDoctors" :key="doctor.specialist">
                <div class="row py-4" v-if="doctor.dataDoctor.filter(result => {return result.name.toLowerCase().includes(search.toLowerCase())}).length > 0">
                    <div class="col-sm-4">
                        <h3 class="text-left h6">Melinda Hospital Doctors</h3>
                        <h3 class="text-left h2 fw-bold">{{doctor.specialist}}</h3>
                    </div>
                    <div class="col-sm-8">
                        <div class="row mb-4 px-0">
                            <template v-for="data in doctor.dataDoctor.sort((a, b) => { return a.sort - b.sort; })" :key="data.doctor_id">
                                <div class="card mb-4 px-1 border-0 col-sm-4 bg-transparent" 
                                    v-if="data.name.toLowerCase().includes(search.toLowerCase())"
                                >
                                    <img :src="data.self_img_url" class="card-img-top" :alt="data.self_img_url" height="165">
                                    <div class="card-body">
                                        <h5 class="card-title text-dark">{{data.name}}</h5>
                                        <a href="#" class="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#exampleModal" @click="getDetailDoctor(data.doctor_id)">
                                            Lihat Jadwal
                                        </a>
                                    </div>
                                </div>                        
                            </template>                       
                        </div>                    
                    </div>
                </div>
            </template>            
        </div>
    </div>
    <!-- Modal Doctor -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl py-5">
            <div class="modal-content" style="background-color: #ffffff">
                <div class="modal-header border-0">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body row px-0 py-4 m-0">
                    <div class="col-sm-12 p-0">
                        <template v-if="detail_doctor.speciality">                                
                            <HeroText 
                                :subTitle="detail_doctor.speciality.name"
                                :title="detail_doctor.name"
                                :content="detail_doctor.about"
                                :textDark="true"
                                :textCenter="true"
                            />     
                        </template>
                    </div>
                    <img class="col-sm-6" :src="detail_doctor.self_img_url" />
                    <div class="col-sm-6">
                        <div class="p-4 border h-100">
                            <div class="d-flex justify-content-between py-1 mb-4 align-items-center">  
                                <div class="w-50 d-flex justify-content-center">
                                    <h5 style="color: #545454 !important;">Hari</h5>                                    
                                </div>
                                <div class="w-50 d-flex justify-content-center">
                                    <h5 style="color: #545454 !important;">Jam Praktek</h5>
                                </div>
                            </div>
                            <div v-for="day in detail_doctor.doctordays" :key="day.day_id">
                                <div class="border d-flex justify-content-around mb-2 py-2">  
                                    <div class="w-50">
                                        <p class="d-flex justify-content-center">{{ day.name }}</p>
                                    </div>
                                    <div class="w-50">
                                        <div v-for="sc in day.schedule" :key="sc.doctor_schedule_id">
                                            <template v-if="sc.appoinment == 1">
                                                <p class="d-flex justify-content-center">By Appointment</p>
                                            </template>
                                            <template v-else>
                                                <p class="d-flex justify-content-center">{{ timeFormat(sc.time_start) }} - {{ timeFormat(sc.time_end) }}</p>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-4">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    Edukasi
                                </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p v-html="detail_doctor.educations"></p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Organisasi
                                </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p v-html="detail_doctor.organitions"></p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Penghargaan
                                </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p v-html="detail_doctor.awards"></p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Publikasi
                                </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p v-html="detail_doctor.publication"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 d-flex justify-content-center my-4">
                        <p>
                            Jadwal dokter dapat berubah sewaktu-waktu, informasi lebih lanjut hubungi RS Melinda 2 (022) 4233777 , atau
                            <a class="btn-whatsapp" target="_blank" :href="'https://api.whatsapp.com/send?phone=628157100888&text=Halo,%20kami%20ingin%20mengenai%20informasi%20lebih%20lanjut%20terkait%20jadwal%20praktek%20'+detail_doctor.name">
                            Whatsapp : 0815 7100 888</a>
                        </p>
                    </div>
                </div>
                <!-- <div class="modal-body row p-0 m-0">
                    <img class="col-sm-6 doctor-img p-4 m-0 align-self-center" :src="detail_doctor.self_img_url" />
                    <div class="col-sm-6 p-4 row m-0">
                        <div class="col-sm-12 p-0">
                            <template v-if="detail_doctor.speciality">                                
                                <HeroText 
                                    :subTitle="detail_doctor.speciality.name"
                                    :title="detail_doctor.name"     
                                    content="Nullam laoreet turpis libero, nec luctus sem vulputate aliquam. Morbi id quam a ipsum laoreet faucibus. Nullam laoreet turpis libero, nec luctus sem vulputate aliquam. Morbi id quam a ipsum laoreet faucibus."                               
                                />     
                            </template>
                        </div>
                        <div class="col-sm-12 px-0 py-4 border">
                            <template v-for="ds in detail_doctor.schedules" :key="ds.day.name">
                                <div class="d-flex justify-content-around">  
                                    <div>
                                        <h5 style="color: #545454 !important;">Hari</h5>
                                        <p>{{ ds.day.name }}</p>
                                    </div>
                                    <div>
                                        <h5 style="color: #545454 !important;">Jam Praktek</h5>
                                        <template v-if="ds.appoinment == 1">
                                            <p>By Appointment</p>
                                        </template>
                                        <template v-else>
                                            <p>{{ ds.time_start }} - {{ ds.time_end }}</p>
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Edukasi
                                </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Organisasi
                                </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Penghargaan
                                </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Publikasi
                                </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 d-flex justify-content-center my-4">
                        <p>
                            Jadwal dokter dapat berubah sewaktu-waktu, informasi lebih lanjut hubungi RSIA Melinda (022) 4222788 , atau 
                            <a class="btn-whatsapp" target="_blank" :href="'https://api.whatsapp.com/send?phone=6285223388888&text=Halo,%20kami%20ingin%20mengenai%20informasi%20lebih%20lanjut%20terkait%20jadwal%20praktek%20'+detail_doctor.name">
                            Whatsapp : 0852 2338 8888</a>
                        </p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <FooterContent />
</template>

<style scoped>    
    a.btn-whatsapp {
        text-decoration: none;
        color: rgb(21, 196, 30);
    }
    a.btn-whatsapp:hover {
        text-decoration: none;
        color: rgb(21, 196, 30);
        opacity: 0.6;
    }
    .doctor-img {
        height: 400px !important;
        object-fit: cover;
        background-size: cover;
    }
    .card {
        height: 280px !important;
    }
    .card-img-top {
        object-fit: cover;        
    }
    .text-left.h6 {
        color: #75C6EF;
    }
    .h2.fw-bold {
        color: #1B4674;
    }
    .btn.btn-outline-primary {
        border: transparent;
        color: #ffffff;        
        background-color: #75C6EF;
    }
    .btn.btn-outline-primary:hover {
        border: transparent;
        color: #ffffff;        
        background-color: #75C6EF;
        opacity: 0.8;
    }
    .modal-body img {
        background-size: cover;
        object-fit: cover;
    }
    .card-body {
        background-color: #1B4674;
        height: fit-content;
    }
    h5 {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        color: #ffffff !important;
        margin: 0;
    }
    a.btn {
        background-color: transparent !important;
        border: 0 solid transparent;
        color: #FCE8CE;
        font-size: 14px;
        padding: 0;
    }
    a.btn:hover {
        background-color: transparent !important;
        border: 0 solid transparent;
        color: #e9ceab;
        font-size: 14px;
        padding: 0;
    }
</style>
