<script>
import axios from "axios"
    import moment from "moment"
    import FooterContent from "@/components/utils/FooterContent.vue"
    export default {
        components: {
            FooterContent
        },
        data() {
            return {
                job: [],
                getDepartment: [],
                skills:[],
                category:null
            }
        },
        methods: {
            format_date(value){
                if (value) {
                    return moment(String(value)).format('DD MMMM YYYY')
                }
            },
        },
        mounted() {
            axios.get('https://backend2.melindadev.com/api/career/'+this.$route.params.id)
            .then(response => {
                this.job = response.data.job;
                this.getDepartment = response.data.job.department;
                this.skills = this.job.required_skills ? this.job.required_skills.split(',') : null;
                // this.category = this.job.category.name;
            })
            .catch(error => {
                console.log(error);
                this.$router.push({path:'/404'});
            });
            console.log(this.skills);
        },
    }
</script>

<template>        
    <section class="container-fluid py-4" style="background-color: #1B4674;">
        <section class="d-flex flex-column flex-md-row justify-content-between content mt-2 pt-4">        
            <div class="d-flex flex-column flex-md-row gap-4 align-items-center" id="data-job">            
                <div class="d-flex flex-column">
                    <h2 class="text-white" v-text="getDepartment.name"></h2>
                    <h1 class="text-white" v-text="job.name"></h1>
                    <p>
                        <span class="text-white" v-text="job.location"></span>
                    </p>
                    <p>
                        <span class="text-white">Join Date</span> : <span class="text-white" v-text="format_date(job.created_at)"></span> - <span class="text-white">Last Updated</span> : <span class="text-white" v-text="format_date(job.updated_at)"></span>
                    </p>
                </div>            
            </div>
        </section>
        <section class="d-flex flex-column flex-md-row justify-content-between content">
            <div class="d-flex flex-column">
                <h2 class="job-item mb-0 text-white">Function :</h2>
                <span class="text-white job-value">{{ job.function }}</span>
            </div>
            <div class="d-flex flex-column">
                <h2 class="job-item mb-0 text-white">Type :</h2>
                <span class="text-white job-value">{{ job.type }}</span>
            </div>
            <div class="d-flex flex-column">
                <h2 class="job-item mb-0 text-white">Experience :</h2>
                <span class="text-white job-value">{{ job.experience }}</span>
            </div>
            <div class="d-flex flex-column">
                <h2 class="job-item mb-0 text-white">Vacancies :</h2>
                <span class="text-white job-value">{{ job.vacancies }}</span>
            </div>
            <a href="#" class="button-browse-job btn btn-priamry align-self-center" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Apply Job <i class="bi bi-arrow-right"></i>
            </a>
        </section>
    </section>
    <section class="container-fluid background-cream py-5 mt-5">
        <div class="container mx-auto row">
            <div class="col-sm-8 row">
                <div class="col-sm-12 p-2 p-md-4 bg-white">
                    <!-- description -->
                    <h1 class="job-value mb-0">Description</h1>
                    <div v-html="job.job_description" class="mb-4"></div>
                    <!-- requirements -->
                    <h1 class="job-value mb-2">Requirements</h1>
                    <div v-html="job.job_requirements" class="mb-4"></div>
                    <!-- functions -->
                    <h1 class="job-value mb-2">Functions</h1>
                    <div v-html="job.job_functions" class="mb-4"></div>                    
                </div>
                <div class="col-sm-12 p-2 p-md-4 required-skills">
                    <h1 class="fs-4 fw-bold text-white mb-3">Required Skills</h1>
                    <span v-if="skills">
                        <span v-for="s in skills" :key="s" class="mx-1 py-2 px-4 badge rounded-pill bg-primary mb-2">
                            {{ s }}
                        </span>                                                
                    </span>
                    <span v-else>
                        <span class="mx-1 py-2 px-4 badge rounded-pill bg-primary mb-2">
                            Tidak Ada Skill
                        </span>
                    </span>                
                </div>
                <div class="col-sm-12 p-2 p-md-4 bg-white">
                    <h1 class="job-value mb-2">Bennefits</h1>
                    <div v-html="job.job_benefits"></div>
                </div>                
            </div>
            <div class="col-sm-4">
                <div class="card-other-jobs p-4">
                    <h5>Looking for other available position ?</h5>
                    <p>Melinda Hospital Jobs</p>
                    <a href="/career" class="button-browse-job btn btn-priamry align-self-center btn-cream">Browse Other Job</a>
                </div>
            </div>
        </div>
    </section>
    <!-- <section class="container-fluid background-cream py-5 mt-5">
        <div class="container mx-auto row">
            <div class="col-sm-8 row">
                <div class="col-sm-12 p-2 p-md-4 bg-white">
                    <h1 class="job-value mb-0">Description</h1>
                    <div class="mb-4" v-html="job.job_description">
                    </div>
                    <h1 class="job-value mb-2">Requirements</h1>
                    <div class="mb-4" v-html="job.job_requirements">
                    </div>
                    <h1 class="job-value mb-2">Functions</h1>
                    <div class="mb-4" v-html="job.job_functions">
                    </div>
                </div>
                <div class="col-sm-12 p-2 p-md-4 required-skills">
                    <h1 class="fs-4 fw-bold text-white mb-3">Required Skills</h1>
                    <span v-for="skill in skills" :key="skill" class="mx-1 py-2 px-4 badge rounded-pill bg-primary mb-2">
                        {{ skill }}
                    </span>
                </div>
                <div class="col-sm-12 p-2 p-md-4 bg-white">
                    <h1 class="job-value mb-2">Bennefits</h1>
                    <div class="mb-4" v-html="job.job_benefits">
                    </div>
                </div>                
            </div>
            <div class="col-sm-4">
                <div class="card-other-jobs p-4">
                    <h5>Looking for other available position ?</h5>
                    <p>Melinda Hospital Jobs</p>
                    <a href="/career" class="button-browse-job btn btn-priamry align-self-center btn-cream">Browse Other Job</a>
                </div>
            </div>
        </div>
    </section> -->
    <FooterContent />
    <!-- Modal Apply Job -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="d-flex flex-column">
                        <h5 class="modal-title" id="authLabel">Form Job Apply</h5>
                        <h2>Jobs Apply Melinda Hospital</h2>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body border-0">
                    <form action="#">
                        <div class="mb-2">
                            <label for="formFile" class="form-label">My Resume</label>
                            <input class="form-control" type="file" id="formFile">
                            <div class="form-text">We accept any PDF or Microsoft Office document that is up to 5MB. Your resume will only be revealed to companies you applied to.</div>
                        </div>
                        <div class="mb-2">
                            <label for="formFile" class="form-label">Make resume visible?</label>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked>
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                                <label class="form-check-label" for="flexRadioDefault2">
                                    No
                                </label>
                            </div>
                            <div class="form-text">Employers can view your resume when searching related keywords, and contact you for potential job offers. You can change this and choose which companies to block later on.</div>
                        </div>                        
                        <div class="mb-2">
                            <label for="exampleInputEmail1" class="form-label">Short Bio</label>
                            <input type="text" class="form-control" />
                        </div>                   
                    </form>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                    <button type="button" class="btn btn-secondary btn-batal" data-bs-dismiss="modal">Batal</button>
                    <button type="button" class="btn btn-primary btn-daftar">Apply Now <i class="bi bi-arrow-right"></i></button>
                </div>                
            </div>
        </div>
    </div>
</template>

<style scoped>
    .btn-daftar {
        background: #1B4674;
        border-radius: 40px;
    }
    .btn-daftar:hover {
        background: #1B4674;
        border-radius: 40px;
        opacity: 0.5;
    }
    .btn-batal {
        color: #474747;
        background-color: transparent;
        border: transparent;
    }
    .btn-batal:hover {
        color: #474747;
        background-color: transparent;
        border: transparent;
        opacity: 0.5;
    }
    .modal-header div h5 {
        font-size: 14px;
        color: #75C6EF
    }
    .modal-header div h2 {
        font-size: 24px;
        color: #1B4674;
        font-weight: bold;
    }
    .required-skills {
        background-color: #75C6EF;
    }
    .required-skills span.badge {        
        background-color: #50A6D1 !important;
    }
    ul {
        list-style: none; /* Remove default bullets */
    }

    ul li::before {
        content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: #75C6EF; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
    li {
        color: #575757 !important;
    }
    p, li {
        line-height: 180%;
        color: #575757 !important;
    }
    h1.job-value {
        font-size: 18px;
    }
    .job-item {
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        color: #575757;
    }
    .job-value {
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;        
        color: #1B4674;
    }
    .list-group-item span { color: #575757; }
    .list-group-item h5 { color: #1B4674; }
    .card-other-jobs {
        background: #75C6EF;
    }
    .card-other-jobs h5 { color: #1B4674; font-weight: bold; font-size: 18px }
    .card-other-jobs p { color: #ffffff; font-size: 14px }
    .btn-cream {
        background-color: #FCE8CE !important;
        color: #575757 !important;
        font-size: 14px !important;
        padding: 8px 24px 8px 24px !important;
    }
    .btn-cream:hover {
        background-color: #e7cca7 !important;
        color: #575757 !important;
        font-size: 14px !important;
        padding: 8px 24px 8px 24px !important;
    }
    .button-browse-job {
        border-radius: 40px;
        background-color: #75C6EF;
        padding: 16px 24px 16px 24px;
        font-size: 18px;
        color: white;
        border: none;
    }
    
    .button-browse-job:hover {
        opacity: 0.8;
        border-radius: 40px;
        background-color: #75C6EF;
        padding: 16px 24px 16px 24px;
        font-size: 18px;
        color: white;
        border: none;
    }
    #data-job h1 { color: #1B4674; font-weight: bold; font-size: clamp(1rem, 2.5vw, 2.5rem); }
    #data-job h2 { color: #75C6EF; font-weight: bold; font-size: clamp(1rem, 2.5vw, 1rem);}
    #data-job p {
        font-weight: 400;
        font-size: 12px;
    }
    .content {
        padding: 0 140px 0 140px;
    }
    img.img-fluid {
        max-width: 24% !important;
    }
    @media (max-width: 575px) {
        .content {
            padding: 10px 20px 10px 20px;
        }
        .button-browse-job {
            border-radius: 40px;
            background-color: #75C6EF;
            padding: 8px 24px 8px 24px;
            font-size: 12px;
            color: white;
            border: none;
        }
        
        .button-browse-job:hover {
            opacity: 0.8;
            border-radius: 40px;
            background-color: #75C6EF;
            padding: 8px 24px 8px 24px;
            font-size: 12px;
            color: white;
            border: none;
        }
    }
    @media (max-width: 1199.98px) {
        .content {
            padding: 0 40px 0 40px;
        }
    }
</style>