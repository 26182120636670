import { defineStore } from "pinia";
import axiosClient from "../axios.js";

export const useVideoStore = defineStore("video", {
    state: () => ({
        // ==================== states used on video page and its relation
        videos: null,
        videoCategories: null,
        videoDetail: null,
        videoWidth: 500
    }),
    getters: {
        // ==========
    },
    actions: {
        // ==================== actions used on video page and its relation
        async setVideos(){
            const response = await axiosClient.get(`video`);
            this.videos = response.data;
        },
        async setVideoCategories(){
            const response = await axiosClient.get(`category/video`);
            this.videoCategories = response.data.data;
        },
        async setVideosByCategory(category){
            if(category == 0){
                const response = await axiosClient.get(`video`);
                this.videos = response.data;
            }else{
                const response = await axiosClient.get(`video?category=` + category);
                this.videos = response.data;
            }
        },
        async searchVideos(query){
            const response = await axiosClient.get(`video?search=` + query);
            this.videos = response.data;
        },
        async setVideoDetail(slug){
            this.videoDetail = null;
            const response = await axiosClient.get(`video/` + slug);
            this.videoDetail = response.data.data;
            console.log(response);
        },
        setVideoWidth(width){
            this.videoWidth = width;
        }
    }
});