<script>
import axios from "axios";
    export default {
        data() {
            return {
                header: {},
                rooms: [],
            }
        },
        mounted() {
            axios.get('https://backend2.melindadev.com/api/room')
            .then(response => (this.rooms = response.data));

            axios.get('https://backend2.melindadev.com/api/page-header/room_card')
            .then(response => {
                this.header = response.data.page_header;
            });
        },
        methods: {
            // buat function yang dimana ketika diklik maka akan dialihkan ke halaman detail
            goToDetail(room_id){
                this.$router.push({name:'our-room-detail', params:{id:room_id}}); 
            }
        }
        
    }

</script>

<template>
    <section id="rooms" class="background-cream container-fluid py-5 px-xl-5">
        <p class="text-center content-sub-title my-4" v-text="header.sub_title"></p>        
        <h1 class="text-center content-title my-4" v-text="header.title"></h1>
        <p class="fw-light my-4" style="text-align: justify;" v-html="header.description">
        </p>
        <div class="mx-auto container-fluid row">            
            <template v-for="room in rooms" v-bind:key="room.id">
                <div class="col-sm-4 mb-4">
                    <div class="card card-article position-relative" @click="goToDetail(room.id)">
                        <img v-bind:src="room.imgUrl" class="room-img card-img-top" alt="hotel-image">
                        <div class="card-body card-article-body position-absolute bottom-0 start-0 w-100 text-white">
                            <h5 class="card-title" v-text="room.title"></h5>                        
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<style scoped>
    .card-article-body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        left: 0;
    }
    .room-img, .card {
        height: 360px;
    }        
    .room-img {
        object-fit: cover;
        background-size: cover;
    }
    .card-article-body {
        background-color: #1a1a1aa4;  
    }    
    .card-article-body h5 a {
        color: white;
        width: 100% !important;
        font-size: 20px;
        font-weight: bold !important;
        text-decoration: none;    
    }
    .card {   
        border: none;
    }
    .card:hover {
        /* background-color: #ffffff00; */
        opacity: 0.5;
        cursor: pointer;
    }
    .konten {
        padding: 48px 10% 48px 10%;     
    }

    .background-cream {
        background-color: #FDF4E9;
    }
    p.fw-light {
        line-height: 150%;
        color: #575757;
    }
    #hero-images-homepage {
        width: 50%;    
        display: block;    
    }

    .wrapper-hero-img {
        margin-top: -30%;
        z-index: 998;
        display: flex;
        justify-content: end;    
    }

    .mouse {
        width: 34px;
    }

    .bg-img-1 {
        height: 460px;
        background-image: url("@/assets/images/services-img-3.png");
        background-size: cover;
        object-fit: cover;    
    }

    .bg-img-2 {
        height: 460px;
        background-image: url("@/assets/images/services-img-4.png");
        background-size: cover;
        object-fit: cover;    
    }

    .bg-img-1 p, .bg-img-2 p {
        color: white !important;
        z-index: 1000;
        filter: brightness(100%) !important;
    }

    #rooms p {
        width: 60%;
        margin: 0 auto;
    }

    .card-room {
        position: relative;
    }

    .card-room-body {
        position: absolute;
        /* bottom: 8px;
        left: 16px; */
    }

    .card-room p {
        color: white;
        width: 100% !important;
        font-size: 20px;
        font-weight: bold !important;    
    }

    @media (max-width: 575px) {
        .wrapper-hero-img {
            margin-top: 0;
            z-index: 0;
            display: none;
            justify-content: end;    
        }
        #rooms p {
            width: 90%;
            margin: 0 auto;
        }
    }

    @media (max-width: 1199.98px) {
        .wrapper-hero-img {
            margin-top: 0;
            z-index: 0;
            display: flex;
            justify-content: end;    
        }
        #rooms p {
            width: 80%;
            margin: 0 auto;
        }
    }
</style>