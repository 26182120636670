import axios from "axios";
const axiosClient = axios.create({
  baseURL: 'https://backend2.melindadev.com/' + 'api/',
  headers: { "Content-Type": "application/json", Accept: "application/json" }
});

axiosClient.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

export default axiosClient;