<script>
    import FooterContent from "@/components/utils/FooterContent.vue"
    import HeroText from "@/components/utils/HeroText.vue"    
    import axios from 'axios'
    export default {
        components: {
            FooterContent, HeroText
        },
        data() {
            return {
                contact : [],
                contact_card: [],
                map : [],
                specialities : [],
                header : [],
                message : {
                    doctor_speciality_id : null,
                    full_name : null,
                    email : null,
                    phone : null,
                    messages : null,
                },
                //state validation
                validation: [],
            }
        },
        mounted() {
            window.scrollTo(0, 0);
            axios.get('https://backend2.melindadev.com/api/contact-us')
            .then(response => {
                this.contact = response.data.contact_us;
                this.map = response.data.map;
            })
            axios.get('https://backend2.melindadev.com/api/page-header/contact')
            .then(response => {
                this.header = response.data.page_header;
            });
            axios.get('https://backend2.melindadev.com/api/page-header/contact_card')
            .then(response => {
                this.contact_card = response.data.page_header;
            });
            axios.get('https://backend2.melindadev.com/api/speciality')
            .then(response => {
                this.specialities = response.data.specialities;
            });
        },
        methods: {
            submitMessage: function() {
                axios.post('https://backend2.melindadev.com/api/contact-us/messages', this.message)
                .then((res) => {
                    console.log(res);
                    alert('Message has been sent');
                })
                .catch((error) => {
                    console.log(error);
                }).finally(() => {
                    //Perform action in always
                });
                
                this.validation = []
                if (!this.message.doctor_speciality_id) {
                    this.validation.doctor_speciality_id = true
                }
                if (!this.message.full_name) {
                    this.validation.full_name = true
                }
                if (!this.message.email) {
                    this.validation.email = true
                }   
                if (!this.message.phone) {
                    this.validation.phone = true
                }
                if (!this.message.messages) {
                    this.validation.messages = true
                }
            },
        }
    }
</script>
<template>    
    <HeroText
        blueTheme
        :subTitle="header.sub_title"
        :title="header.title"
        :content="header.description"
    />
    <!-- content -->
    <section class="container-fluid py-5 px-5 row">
        <div class="col-sm-6 mb-5 mb-md-0 px-0 px-md-5">
            <span class="content-sub-title mb-1">{{ contact_card.sub_title }}</span>
            <h1 class="content-title fw-bold mb-2">{{ contact_card.title }}</h1>
            <div class="row mt-4">
                <div class="col-sm-6">
                    <span class="content-sub-title text-dark">Office</span>
                    <div class="my-2"></div>
                    <p>{{ contact.address }}</p>
                </div>
                <div class="col-sm-6">
                    <span class="content-sub-title text-dark">Contact</span>
                    <div class="my-2"></div>
                    <p v-html="contact.contact">
                    </p>
                </div>
                <div class="col-sm-6">
                    <span class="content-sub-title text-dark">Email</span>
                    <div class="my-2"></div>
                    <p>{{ contact.email }}</p>
                </div>
            </div>         
        </div>
        <div class="col-sm-6">
            <form @submit.prevent="submitMessage">
                <div class="mb-3">
                  <label for="select-department">Spesialisasi</label>
                  <select class="form-select" aria-label="select-department" v-model="message.doctor_speciality_id">
                    <option v-for="(speciality) in specialities" :key="speciality.doctor_speciality_id" 
                    :value="speciality.doctor_speciality_id">
                    {{ speciality.name }}
                    </option>
                  </select>
                    <div v-if="validation.doctor_speciality_id" class="mt-2 alert alert-danger">
                        Enter Spesialisasi
                    </div>
                </div>
                <div class="mb-3">
                  <label class="form-label">Full Name</label>
                  <input type="text" class="form-control" v-model="message.full_name" />
                    <div v-if="validation.full_name" class="mt-2 alert alert-danger">
                        Enter Full Name
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Email Adress</label>
                    <input type="email" class="form-control" v-model="message.email" />
                    <div v-if="validation.email" class="mt-2 alert alert-danger">
                        Enter Email Address
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Phone Number</label>
                    <input type="text" class="form-control" v-model="message.phone" />
                    <div v-if="validation.phone" class="mt-2 alert alert-danger">
                        Enter Phone Number
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Messages</label>
                    <textarea class="form-control" id="messages" rows="3" v-model="message.messages"></textarea>
                    <div v-if="validation.messages" class="mt-2 alert alert-danger">
                        Enter Messages
                    </div>
                </div>
                <button type="submit" class="btn btn-primary">Send Us</button>
            </form>
        </div>        
    </section>
    <div class="mapouter">
        <div class="gmap_canvas">
            <iframe width="1080" height="400" id="gmap_canvas" :src="'https://maps.google.com/maps?q='+map.latitude+','+map.longitude+'&hl=id&z=14&amp;output=embed'" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            <a href="https://putlocker-is.org"></a><br>
            <a href="https://www.embedgooglemap.net">integrate google maps into website</a>            
        </div>
    </div>
    <FooterContent />
</template>

<style scoped>
    .blue-theme {
        background: #1B4674;
    }
    iframe {width:100%;height:100%;} .mapouter{position:relative;text-align:right;height:400px;width:100%;}
    .gmap_canvas {overflow:hidden;background:none!important;height:400px;width:100%;}
    .konten {
        padding: 48px 10% 48px 10%;     
    }

    .background-cream {
        background-color: #FDF4E9;
    }
    p.fw-light {
        line-height: 150%;
        color: #575757;
    }
    #hero-images-homepage {
        width: 50%;    
        display: block;    
    }

    .wrapper-hero-img {
        margin-top: -30%;
        z-index: 998;
        display: flex;
        justify-content: end;    
    }

    .mouse {
        width: 34px;
    }

    .bg-img-2 {
        height: 460px;
        background-image: url("@/assets/images/services-img-4.png");
        background-size: cover;
        object-fit: cover;    
    }

    #rooms p {
        width: 60%;
        margin: 0 auto;
    }

    .card-room {
        position: relative;
    }

    .card-room-body {
        position: absolute;
        bottom: 8px;
        left: 16px;
    }

    .card-room p {
        color: white;
        width: 100% !important;
        font-size: 20px;
        font-weight: bold !important;    
    }

    #map {
        height: 400px;
        /* The height is 400 pixels */
        width: 100%;
        /* The width is the width of the web page */
    }

    @media (max-width: 575px) {
        .wrapper-hero-img {
            margin-top: 0;
            z-index: 0;
            display: none;
            justify-content: end;    
        }
        #rooms p {
            width: 90%;
            margin: 0 auto;
        }
    }

    @media (max-width: 1199.98px) {
        .wrapper-hero-img {
            margin-top: 0;
            z-index: 0;
            display: flex;
            justify-content: end;    
        }
        #rooms p {
            width: 80%;
            margin: 0 auto;
        }
    }
</style>