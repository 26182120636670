<template>
    <RoomAvailabilityHeaderComponent/>
    <RoomAvailabilityComponent/>
    <FooterContent/>
</template>
<script setup>
    import RoomAvailabilityHeaderComponent from '@/components/our-room/RoomAvailabilityHeaderComponent.vue';
    import RoomAvailabilityComponent from '@/components/our-room/RoomAvailabilityComponent.vue';
    import FooterContent from '@/components/utils/FooterContent.vue';
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
</script>
<style>
    /*  */
</style>