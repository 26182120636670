<template>
    <HeroText 
        :subTitle="header.sub_title ? header.sub_title : ''"
        :title="header.title ? header.title : ''"
        :content="header.description ? header.description : ''"
        :blueTheme="true"
    />
    <section class="container-fluid mt-5 row">
        <template v-for="article in articles.slice(0, this.articlesToShow)" :key="article.article_id">
            <!-- {{ article }} -->
            <div class="col-sm-4">
                <div class="card text-white border-0 mb-4">
                    <img :src="article.img_url" class="article-img card-img" :alt="article.title">                    
                    <div class="card-img-overlay card-article-body">
                        <span class="badge bg-primary">{{article.category.name}}</span>
                        <h5 class="card-title mt-2">
                            <router-link :to="{ name: 'artikel', params: { id: article.slug } }" >
                                {{article.title}}
                            </router-link>
                        </h5>
                    </div>
                </div>
            </div>
        </template>        
    </section>
    <div class="container-fluid d-flex justify-content-center mb-5">
        <button class="btn btn-primary btn-loadmore rounded-pill" @click="loadMoreHandle()">
            Load More
        </button>
    </div>
    <FooterContent />
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'
    import FooterContent from "@/components/utils/FooterContent.vue"
    import HeroText from "@/components/utils/HeroText.vue"
    export default {
        data() {
            return {
                articles: [],
                header : {},
                articlesToShow: 6
            }
        },
        components: {
            FooterContent,
            HeroText,
        },
        methods: {
            format_date(value){
                if (value) {
                    return moment(String(value)).format('DD MMMM YYYY')
                }
            },
            loadMoreHandle() {
                // alert("OK")
                this.articlesToShow += 6
            }
        },
        mounted() {
            let params = {};
            if(this.$route.params.category){
                params['article_categori_id'] = this.$route.params.category;
            }
            axios.get('https://backend1.melindadev.com/api/article',{
                params
            })
            .then(response => (this.articles = response.data.articles));
            
            axios.get('https://backend1.melindadev.com/api/page-header/article')
            .then(response => {
                this.header = response.data.page_header;
            });
        },
    }
</script>

<style scoped>
    .btn-loadmore {
        border: transparent;
        background-color:#75C6EF;
    }
    .btn-loadmore:hover {
        border: transparent;
        background-color:#5aadd6;
    }
   .badge.bg-primary {
        background-color: #1B4674 !important;
    }
    img.article-img{
        width: 100%;
        height: 100%;
        background-size: cover;
        object-fit: cover;
    }
    .card {
        height: 360px;
    }        
    .card-article-body {
        margin-top: auto;
        background-image: linear-gradient(rgba(34, 34, 34, 0.808), rgb(36, 36, 36), rgb(34, 34, 34));
        height: 40%;     
    }    
    .card-article-body h5 a {
        color: white;
        width: 100% !important;
        font-size: 20px;
        font-weight: bold !important;
        text-decoration: none;    
    }
    .card-article-body h5 a:hover {
        color: white;
        width: 100% !important;
        font-size: 20px;
        font-weight: bold !important;
        text-decoration: none;
        opacity: 0.5;    
    }
    @media (max-width: 1199.98px) {
        footer #contact {        
            padding: 48px 8% 48px 8%;
            justify-content: space-between;
            background-color: #FEF8F0;        
        }
        footer hr { width: 84%; }
    }
</style>