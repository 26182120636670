<script>
    import axios from "axios";
    import FooterContent from "@/components/utils/FooterContent.vue"
    import HeroText from "@/components/utils/HeroText.vue"
    import CardRooms from "@/components/our-room/CardRooms.vue"    
    import HeadingContent from "@/components/utils/HeadingContent.vue"
    export default {
        components: {
            FooterContent, HeroText, CardRooms, HeadingContent
        },
        data() {
            return {
                header : {},
                header_room_card: [],
                comment : [],
            }
        },
        mounted() {
            window.scrollTo(0, 0);
            axios.get('https://backend2.melindadev.com/api/page-header/room')
            .then(response => {
                this.header = response.data.page_header;
            });
            axios.get('https://backend2.melindadev.com/api/page-header/room_comment')
            .then(response => {
                this.header_room_card = response.data.page_header;
            });
            axios.get('https://backend2.melindadev.com/api/comment/room')
            .then(response => {
                this.comment = response.data.comment;
            });
        },
    }
</script>

<template>    
    <HeroText
        blueTheme
        :subTitle="header.sub_title"
        :title="header.title"
        :content="header.description"
    />
    <CardRooms />
    <!-- display none -->
    <div class="cotainer mx-auto row blue-theme py-5">     
        <div class="col-sm-12 row">
            <div class="col-sm-12 py-4 d-flex justify-content-center flex-column gap-2">
                <span class="content-sub-title text-center mx-auto" style="color: #75C6EF;">
                    {{ header_room_card.sub_title }}
                </span>
                <h4 class="content-title text-white text-center">
                    {{ header_room_card.title }}
                </h4>
            </div>
            <template v-for="(com, index) in comment" :key="com.name">
                <template v-if="index == 0">
                    <div class="col-sm-6 px-5 py-4 border-end">
                        <HeadingContent 
                            white_title 
                            :subTitle="com.sub_title" 
                            :title="com.title" 
                            :paragraph="com.comment" 
                        >
                            <p class="text-white fw-bold">{{ com.name }}</p>
                        </HeadingContent>
                    </div>
                </template>
                <template v-else>
                    <div class="col-sm-6 px-5 py-4">
                        <HeadingContent 
                            white_title 
                            :subTitle="com.sub_title" 
                            :title="com.title" 
                            :paragraph="com.comment" 
                        >
                            <p class="text-white fw-bold">{{ com.name }}</p>
                        </HeadingContent>
                    </div>
                </template>
            </template>
        </div>
    </div>
    <FooterContent />
</template>

<style scoped>
    .blue-theme {
        background: #1B4674;
    }
</style>