<template>
    <section class="background-cream container-fluid p-0 m-0">        
        <section class="py-5 container-fluid mx-auto" style="background-color: #1B4674;">
            <div class="container mx-auto row p-0">
                <div class="col-sm-6">
                    <div class="row" v-for="(image, index) in images" :key="image.id">
                        <div class="card col-sm-12 p-2 bg-transparent" v-if="index == 0">
                            <img v-bind:src="image.img_url" class="card-img-top thumbnail" :alt="image.title" @click="toggler = !toggler" />
                        </div>
                    </div>
                    <div class="row">
                        <template v-for="(image, index) in images.slice(1)" :key="image.id">
                            <div class="col-4 col-md-3 p-2">
                                <div class="card" @click="changePhoto(index + 1)">
                                    <img
                                        v-bind:src="image.img_url"
                                        class="card-img-top"
                                        :alt="image.title"
                                        v-if="index < 4"
                                    />
                                </div>
                            </div>
                        </template>
                    </div>
                    <!-- Lightbox -->
                    <div>
                        <FsLightbox
                            :toggler="toggler"
                            :sources="img"
                            type="image"
                            :sourceIndex="lightboxIndex"                            
                        />
                    </div>
                </div>
                <div class="col-sm-6 align-self-center">
                    <section class="container d-flex justify-content-start flex-column my-2" style="padding-left: 0;">
                        <span class="content-sub-title text-left" style="color: #75C6EF">{{ subtitle }}</span>
                        <h1 class="content-title text-left fs-1 fw-bold text-white">{{ title }}</h1>
                    </section>
                    <div class="row my-2">
                        <template v-for="(c) in checklist" :key="c">
                            <div class="col-sm-6 d-flex align-items-start mb-2">                        
                                <i class="bi bi-check-lg mr-2"></i>
                                <p class="text-white">{{ c.name }} ({{ c.qty }})</p>
                            </div>
                        </template>
                    </div>
                    <router-link class="btn btn-primary my-2" to="/our-room">Discover for Next Room</router-link>
                </div>
            </div>
        </section>
        <section class="container-fluid mx-auto py-5">
            <div class="container mx-auto d-flex flex-column">
                <h4>Other Room</h4>
                <div class="mx-auto container-fluid row p-0 mt-4">
                    <template v-for="(room) in rooms.slice(0, 3)" v-bind:key="room.id">
                        <div class="col-sm-4 mb-4">
                            <a :href="'/our-room-detail/'+room.id">
                                <div class="card card-article position-relative">
                                    <img v-bind:src="room.imgUrl" class="room-img card-img-top" alt="hotel-image">
                                    <div class="card-body card-article-body position-absolute bottom-0 start-0 w-100 text-white">
                                        <h5 class="card-title" v-text="room.title"></h5>                        
                                    </div>
                                </div>
                            </a>
                        </div>
                    </template>
                </div>
            </div>
        </section>    
    </section>
    <FooterContent />
</template>

<script>
    import axios from "axios";
    import FsLightbox from "fslightbox-vue/v3";
    import FooterContent from "@/components/utils/FooterContent.vue"
    export default {
        components: {
            FooterContent, FsLightbox
        },
        data() {
            return {
                toggler: false,
                subtitle: '',
                title: '',
                checklist : [],
                images : [],
                img: [],
                comment : {
                    sub_title : null,
                    title : null,
                    name : null,
                    comment : null,
                    img_url : null,
                },
                rooms: []
            }
        },
        methods: {
            // goToDetail(room_id){
            //     this.$router.push({name:'our-room-detail', params:{id:room_id}}); 
            // }
            changePhoto(index) {
                this.toggler = !this.toggler;
                this.lightboxIndex = index;
            }
        },
        mounted() {
            window.scrollTo(0,0);
            axios.get('https://backend2.melindadev.com/api/room/'+this.$route.params.id)
            .then(response => {
                this.subtitle = response.data.subtitle;
                this.title = response.data.title;
                this.checklist = response.data.checklist;
                this.images = response.data.images;
                this.img = response.data.image;
            });
            axios.get('https://backend2.melindadev.com/api/comment/room')
            .then(response => {
                this.comment = response.data.comment;
            });
            axios.get('https://backend2.melindadev.com/api/room')
            .then(response => (this.rooms = response.data));
        },
    }
</script>

<style scoped>
    .card-article-body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        left: 0;
    }
    .room-img, .card {
        height: 360px;
    }        
    .room-img {
        object-fit: cover;
        background-size: cover;
    }
    .card-article-body {
        background-color: #1a1a1aa4;  
    }    
    .card-article-body h5 a {
        color: white;
        width: 100% !important;
        font-size: 20px;
        font-weight: bold !important;
        text-decoration: none;    
    }
    .card {   
        border: none;
    }
    .card:hover {
        /* background-color: #ffffff00; */
        opacity: 0.5;
        cursor: pointer;
    }
    h4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        color: #1B4674;
    }
    a.btn {
        background-color: #FCE8CE;
        border-radius: 40px;
        border: transparent;
        font-weight: 700;
        font-size: 24px;
        color: #1B4674;
    }
    a.btn:hover {
        background-color: #FCE8CE;
        border-radius: 40px;
        border: transparent;
        font-weight: 700;
        font-size: 24px;
        color: #1B4674;
        opacity: 0.5;
    }
    .card {
        padding: 0;
        height: fit-content;
    }
    .thumbnail {
        max-height: 320px;
        object-fit: cover;
    }
    span {
        color: #474747;
    }
    .bi.bi-check-lg {
        color: #75C6EF;
    }
</style>