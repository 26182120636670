<template>    
    <section class="container-fluid blue-theme p-5">
        <HeadingContent 
            white_title
            :subTitle="header.sub_title"
            :title="header.title"
            :paragraph="'Luas Bangunan: '+header.wide"
        />
        <div class="cotainer mx-auto row">
            <template v-for="p in plan" :key="p.plan_id">
                <div class="col-sm-4 border py-4">
                    <span class="content-sub-title mb-1">{{ p.sub_title }}</span>
                    <h1 class="heading fw-bold mb-4 text-white">{{ p.title }}</h1> 
                    <p class="fw-light text-white">
                        {{ p.description }}
                    </p>
                </div>
            </template>
        </div>
    </section>
</template>

<script>
    import axios from "axios";
    import HeadingContent from "@/components/utils/HeadingContent.vue"
    export default {    
        data() {
            return {
                header: [],
                plan: []
            }
        },
        components: {
            HeadingContent
        },
        mounted() {
            axios.get('https://backend2.melindadev.com/api/about').then(
                response => {
                    this.header = response.data.plan_header;
                    this.plan = response.data.plans
                }
            )
        }
    }
</script>

<style scoped>
    .blue-theme {
        background: #1B4674;
    }
</style>