<template>
    <div class="container mx-auto row px-0 py-4">
        <div class="col-sm-12 py-4">
            <span class="content-sub-title text-start">{{ data_filosofy.sub_title }}</span>
            <h1 class="content-title text-start" >
                {{ data_filosofy.title }}
            </h1>
        </div>
        <div class="col-sm-12">
            <p v-html="desc_filosofy"></p>
        </div>
    </div>
</template>

<script>    
    import axios from "axios"
    export default {
        data() {
            return {
                data_filosofy: [],
                desc_filosofy: ""
            }
        },
        mounted() {
            axios.get('https://backend2.melindadev.com/api/about').then(
                response => {
                    this.data_filosofy = response.data.filosofy
                    this.desc_filosofy = response.data.filosofy.description
                }
            )
        }
    }
</script>

<style scoped>

</style>