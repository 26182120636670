<template>
    <section class="container-fluid">
        <div class="container mx-auto p-5 d-flex flex-column justify-content-center align-items-center">
            <h1 class="fs-1 fw-bold">404</h1>
            <p class="fw-light">Mohon maaf, halaman yang dituju tidak dapat ditemukan.</p>
            <a href="/" class="btn btn-primary link-to-website">Kembali ke Beranda</a>
        </div>
    </section>
</template>

<script>
    export default {

    }
</script>

<style scoped>
    .container-fluid {
        background-color: #1C4677;
    }
    h1 {
        color: #ffffff;
    }
    p {
        color: #75C6EF;
    }
    .container {
        height: 100vh;
    }
</style>