<template>
    <section class="container-fluid blue-theme p-5">
        <div class="cotainer mx-auto row">         
            <div class="col-sm-6 row border-end gap-4">
                <div class="col-sm-12 py-4">
                    <HeadingContent 
                        white_title 
                        :subTitle="info.vision_sub_title" 
                        :title="info.vision_title" 
                        :paragraphHtml="info.vision_description"
                    />
                </div>
                <div class="col-sm-12 py-4">
                    <HeadingContent 
                        white_title 
                        :subTitle="info.mission_sub_title" 
                        :title="info.mission_title"
                        :paragraphHtml="info.mission_description"
                    />
                </div>
            </div>
            <div class="col-sm-6 row ps-0 ps-md-5">
                <div class="col-sm-12 py-4">                    
                    <HeadingContent 
                        white_title 
                        :subTitle="motto_purpose.purpose_sub_title" 
                        :title="motto_purpose.purpose_title"
                    >
                        <p class="text-white" v-html="motto_purpose.purpose_description"></p>
                    </HeadingContent>
                </div>
                <div class="col-sm-12 py-4">
                    <HeadingContent 
                        white_title 
                        :subTitle="motto_purpose.motto_sub_title" 
                        :title="motto_purpose.motto_title"
                    >
                        <p class="text-white" v-html="motto_purpose.motto_description"></p>
                    </HeadingContent>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import HeadingContent from "@/components/utils/HeadingContent.vue"
    import axios from "axios"
    export default {
        components: { HeadingContent },
        data() {
            return {
                info: [],
                motto_purpose: [],
                tujuan: [],
            }
        },
        // di takeout dulu karena ada penyesuaian UI
        mounted() {
            axios.get('https://backend2.melindadev.com/api/about').then(
                response => {
                    this.info = response.data.vision_mission;
                    this.motto_purpose = response.data.motto_purppose;                    
                }
            );
        },
    }
</script>

<style scoped>
    .blue-theme {
        background: #1B4674;
    }
</style>