<script>
    import HeadingContent from "@/components/utils/HeadingContent.vue";    
    export default {
        components: {
            HeadingContent
        },
        props: {
            data_point: Array
        },        
    }
</script>

<template>    
    <div id="carouselExampleControls-two" class="slide" data-bs-ride="carousel" style="z-index: -999 !important;">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="row">
                    <div class="col-sm-6 px-5 py-5 py-md-0 my-auto">
                        <HeadingContent 
                            :subTitle="data_point.sub_title"
                            :title="data_point.title"
                            :paragraph="data_point.description"
                            white_title
                        >
                        </HeadingContent>
                    </div>
                    <div class="col-sm-6">                        
                        <img :src="data_point.img_url" class="img-carousel d-none d-md-block w-100" :alt="data_point.title">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid py-5 px-5 m-0 row slider-bottom-content">
        <div class="col-sm-6 align-self-end">
            <p class="fst-italic fw-light text-white" v-html="data_point.point_description"></p>
        </div>
        <div class="col-sm-6 row">
            <div class="col-sm-4">
                <div class="d-flex flex-column">
                    <p class="fw-bold text-white m-0 fs-3">{{ data_point.doctor_point }}</p>
                    <p class="fw-light text-white m-0">{{ data_point.doctor_text }}</p>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="d-flex flex-column">
                    <p class="fw-bold text-white m-0 fs-3">{{ data_point.nurse_point }}</p>
                    <p class="fw-light text-white m-0">{{ data_point.nurse_text }}</p>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="d-flex flex-column">
                    <p class="fw-bold text-white m-0 fs-3">{{ data_point.patient_point }}</p>
                    <p class="fw-light text-white m-0">{{ data_point.patient_text }}</p>
                </div>
            </div>
        </div>
    </div>    
</template>

<style scoped>
    .slider-bottom-content {                
        background-color:#1B4674;
    }
    .img-carousel {
        background-size: cover;
        object-fit: cover;        
        height: 60vh;
    }
    .controller-info {
        background-color:#1B4674;
    }
    .btn-pendaftaran {
        background: #75C6EF;
        border: transparent;       
    }
    .btn-pendaftaran:hover {
        background: #75C6EF;
        border: transparent;
        opacity: 0.5;       
    }
    .btn-control-next {
        background: #75C6EF;
        border: transparent;
        color: white;
    }
    .btn-control-next:hover {
        background: #75C6EF;
        border: transparent;
        color: white;
        opacity: 0.8;
    }
    .btn-control-prev {
        background: #75c6ef7a;
        border: transparent;
        color: white;
    }
    .btn-control-prev:hover {
        background: #75C6EF;
        border: transparent;
        color: white;
        opacity: 0.8;
    }
    #carouselExampleControls-two  {
        z-index: -999;
    }
    .controller-slider .col-sm-8 {        
        z-index: 1000;
        margin-top: -185px;
    }
    .btn-readmore {
        color: #575757;
        background-color: #FCE8CE;
        border: transparent;
    }
    .btn-readmore:hover {
        color: #575757;
        background-color: #FCE8CE;
        border: transparent;
        opacity: 0.8;
    }
    .carousel-item .row {
        background-color:#1B4674;
    }
</style>