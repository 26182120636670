<template>
    <section>
        <div class="section-head">
            <HeadingContent 
                subTitle="Melinda Hospital Video"
                title="Video Kesehatan"
                no_text
            />
        </div>
        <div class="section-body">
            <!-- {{ videoCategories }} -->
            <div class="video-list" v-if="videos">
                <div class="video-list-part" v-for="video in videos.data" :key="video.id">
                    <router-link :to="'/video/detail/' + video.slug">
                        <div class="video-card">
                            <div class="video-card-image">
                                <img :src="video.thumbnail" alt="rumah sakit melinda 2" />
                            </div>
                            <div class="video-card-text">
                                <div class="video-card-title">
                                    <h3>{{ wordsLimit(video.name, 35) }}</h3>
                                </div>
                                <div class="video-card-description">
                                    <!-- <div v-html="video.description"></div> -->
                                    <p>{{ wordsLimit(removeHTML(video.description), 50) }}</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <!-- <div class="video-list-part">
                    <router-link to="/video/detail/penile-implant">
                        <div class="video-card">
                            <div class="video-card-image">
                                <img src="../../assets/images/hq720.webp" alt="rumah sakit melinda 2" />
                            </div>
                            <div class="video-card-text">
                                <div class="video-card-title">
                                    <h3>PENILE IMPLANT PERTAMA DI INDONESIA</h3>
                                </div>
                                <div class="video-card-description">
                                    <p>Deskripsi video.....</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div> -->
                <!-- <div class="video-list-part">
                    <router-link to="/video/detail/orthopaedi-traumatologi">
                        <div class="video-card">
                            <div class="video-card-image">
                                <img src="../../assets/images/bssvid.webp" alt="rumah sakit melinda 2" />
                            </div>
                            <div class="video-card-text">
                                <div class="video-card-title">
                                    <h3>{{ wordsLimit(vtitle, 35) }}</h3>
                                </div>
                                <div class="video-card-description">
                                    <p>{{ wordsLimit(vdesc, 50) }}</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div> -->
                <!-- <div class="video-list-part">
                    <router-link to="/video/detail/video-baru">
                        <div class="video-card">
                            <div class="video-card-image">
                                <img src="https://i.ytimg.com/vi/dDI8iSnsnms/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCcQeMOjmXuZL8Sgpj4gGHSSn9Pog" alt="rumah sakit melinda 2" />
                            </div>
                            <div class="video-card-text">
                                <div class="video-card-title">
                                    <h3>Judul Video</h3>
                                </div>
                                <div class="video-card-description">
                                    <p>Deskripsi video.....</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="video-list-part">
                    <router-link to="/video/detail/video-baru">
                        <div class="video-card">
                            <div class="video-card-image">
                                <img src="https://i.ytimg.com/vi/K-Ib04nqtr4/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBak6v-2-0rx8O1fQBuuGzsSROY9w" alt="rumah sakit melinda 2" />
                            </div>
                            <div class="video-card-text">
                                <div class="video-card-title">
                                    <h3>Judul Video</h3>
                                </div>
                                <div class="video-card-description">
                                    <p>Deskripsi video.....</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="video-list-part">
                    <router-link to="/video/detail/video-baru">
                        <div class="video-card">
                            <div class="video-card-image">
                                <img src="https://i.ytimg.com/vi/9Hxjya1GgdI/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCaXbRNkD-bdi2HLE-25E68sFn9JA" alt="rumah sakit melinda 2" />
                            </div>
                            <div class="video-card-text">
                                <div class="video-card-title">
                                    <h3>Judul Video</h3>
                                </div>
                                <div class="video-card-description">
                                    <p>Deskripsi video.....</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="video-list-part">
                    <router-link to="/video/detail/video-baru">
                        <div class="video-card">
                            <div class="video-card-image">
                                <img src="https://i.ytimg.com/vi/tNg1ZrWcvKg/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDyMx-iJsyYuliOp3PI8iEHMb4jPw" alt="rumah sakit melinda 2" />
                            </div>
                            <div class="video-card-text">
                                <div class="video-card-title">
                                    <h3>Judul Video</h3>
                                </div>
                                <div class="video-card-description">
                                    <p>Deskripsi video.....</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="video-list-part">
                    <router-link to="/video/detail/video-baru">
                        <div class="video-card">
                            <div class="video-card-image">
                                <img src="https://i.ytimg.com/an_webp/UQdmNHRqXsw/mqdefault_6s.webp?du=3000&sqp=CLijqpkG&rs=AOn4CLCBIbD6CZ-jCnEpxghYwwgkr_1gTQ" alt="rumah sakit melinda 2" />
                            </div>
                            <div class="video-card-text">
                                <div class="video-card-title">
                                    <h3>Judul Video</h3>
                                </div>
                                <div class="video-card-description">
                                    <p>Deskripsi video.....</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="video-list-part">
                    <router-link to="/video/detail/video-baru">
                        <div class="video-card">
                            <div class="video-card-image">
                                <img src="https://i.ytimg.com/vi/9LWmM-3lb84/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDzpMtyCpYx-vdM-ntJPM-ZJms4NQ" alt="rumah sakit melinda 2" />
                            </div>
                            <div class="video-card-text">
                                <div class="video-card-title">
                                    <h3>Judul Video</h3>
                                </div>
                                <div class="video-card-description">
                                    <p>Deskripsi video.....</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="video-list-part">
                    <router-link to="/video/detail/video-baru">
                        <div class="video-card">
                            <div class="video-card-image">
                                <img src="https://i.ytimg.com/vi/Dngsi8X6wqc/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAO3trM01BPaZNZKI-6y25F5XfpcA" alt="rumah sakit melinda 2" />
                            </div>
                            <div class="video-card-text">
                                <div class="video-card-title">
                                    <h3>Judul Video</h3>
                                </div>
                                <div class="video-card-description">
                                    <p>Deskripsi video.....</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="video-list-part">
                    <router-link to="/video/detail/video-baru">
                        <div class="video-card">
                            <div class="video-card-image">
                                <img src="https://i.ytimg.com/an_webp/dnzxKYr5ZFc/mqdefault_6s.webp?du=3000&sqp=CM7BqpkG&rs=AOn4CLBrEDjSj6XmHhAHWpUruBrYsAVnOg" alt="rumah sakit melinda 2" />
                            </div>
                            <div class="video-card-text">
                                <div class="video-card-title">
                                    <h3>Judul Video</h3>
                                </div>
                                <div class="video-card-description">
                                    <p>Deskripsi video.....</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div> -->
            </div>
        </div>
    </section>
</template>
<script setup>
    import { computed } from "vue";
    import { useVideoStore } from "@/stores/video";
    import HeadingContent from "@/components/utils/HeadingContent.vue";
    // const vtitle = 'Biportal Endoscopic Spine Surgery';
    // const vdesc = 'Biportal Endoscopic Spine Surgery merupakan prosedur bedah minimal invasif / minim luka di tubuh untuk menangani tulang belakang yang mengakibatkan urat saraf kejepit';
    function wordsLimit(words, max){
        if(words.length > max) words = words.substring(0, max) + '...';
        return words;
    }
    function removeHTML(html){
        // var html = "<p>Some HTML</p>";
        var div = document.createElement("div");
        div.innerHTML = html;
        var text = div.textContent || div.innerText || "";
        return text;
    }
    const videos = computed(() => useVideoStore().videos);
    // const videoCategories = computed(() => useVideoStore().videoCategories);
    useVideoStore().setVideos();
    // useVideoStore().setVideoCategories();
</script>
<style scoped>
    section{
        padding: 2rem;
        padding-top: 3.5rem;
        padding-left: 5%;
        padding-right: 5%;
        /* border: 1px solid green; */
        /* z-index: -1; */
        transition: 0.2s;
    }
    .section-head{
        padding: 0.5rem;
    }
    .video-list{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        /* margin: auto; */
    }
    .video-list-part{
        width: 25%;
        padding: 0.5rem;
        transition: 0.2s;
    }
    .video-card{
        position: relative;
        /* border: 1px solid lightgrey; */
        /* border-radius: 5px; */
        overflow: hidden;
        height: 100%;
    }
    .video-card-image{
        width: 100%;
    }
    .video-card-image img{
        width: 100%;
    }
    .video-card-text{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0.5rem;
        background: rgba(0, 0, 0, 0.315);
        color: #ffffff;
    }
    .video-card-title h3{
        font-size: 1.1rem;
        font-weight: 700;
        margin: 0;
        margin-bottom: 1rem;
    }
    .video-card-description p{
        color: #ffffff;
        font-size: 1rem;
        margin: 0;
    }
    @media only screen and (max-width: 1500px){
        /*  */
    }
    @media only screen and (max-width: 1200px){
        .video-list-part{
            width: 33.33%;
        }
    }
    @media only screen and (max-width: 1000px){
        section{
            padding-top: 5rem;
        }
    }
    @media only screen and (max-width: 850px){
        .video-list-part{
            width: 50%;
        }
    }
    @media only screen and (max-width: 750px){
        .video-list-part{
            width: 100%;
        }
    }
    @media only screen and (max-width: 500px){
        section{
            padding-top: 7.5rem;
        }
    }
    /*  */
</style>