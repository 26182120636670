<template>
    <section>
        <div class="hdf-image" id="hdf-image">
            <div class="hcb-button hcb-left">{{ '<' }}</div>
            <div class="hcb-button hcb-right">{{ '>' }}</div>
            <div class="fullscreen-button" @click="fullScreen('hdf-image');">[fullscreen]</div>
            <div class="hh-carousel carousel hhc-smooth">
                <div class="hc-part" v-for="image in galleries" :key="image.art_galeri_id">
                    <div class="hcp-inner" id="hcp-inner">
                        <div class="img-cover"></div>
                        <img :src="image.img_url" alt="melinda care" />
                    </div>
                    <div class="hcp-description">
                        <div class="hcp-description-inner">
                            <h2>{{ image.name }}</h2>
                            <p>{{ image.place }}, {{ image.date }}</p>
                            <p>{{ image.resolution }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { useRoute } from 'vue-router';
    import { computed } from "@vue/runtime-core";
    import { useGalleryStore } from "../../stores/gallery";
    import { onMounted } from 'vue';
    const galleries = computed(() => useGalleryStore().galleries);
    console.log(process.env.VUE_APP_ROOT_API);
    useGalleryStore().setGalleries(useRoute().params.id);
    function fullScreen(id){
        const el = document.getElementById(id);
        const slider = document.querySelector('.carousel');
        const fsbtn = document.querySelector('.fullscreen-button');
        if(!document.fullscreenElement){
            if(el.requestFullscreen){
                el.requestFullscreen();
            }else if(el.webkitRequestFullscreen){ /* Safari */
                el.webkitRequestFullscreen();
            }else if(el.msRequestFullscreen){ /* IE11 */
                el.msRequestFullscreen();
            }
            fsbtn.innerHTML = '[exit]';
        }else{
            if(document.exitFullscreen){
                document.exitFullscreen();
            }
            fsbtn.innerHTML = '[fullscreen]';
        }
        setTimeout(function(){
            if(slider.scrollLeft > 0){
                slider.scrollLeft = 0;
            }
        }, 270);
    }
    onMounted(() => {
        let isDown = false;
        let startX;
        let scrollLeft;
        const slider = document.querySelector('.carousel');
        const btleft = document.querySelector('.hcb-left');
        const btright = document.querySelector('.hcb-right');
        // const schildren = slider.children;
        const sparent = slider.parentElement;
        const end = () => {
            isDown = false;
            slider.classList.add('hhc-smooth');
            slider.scrollLeft = Math.round(slider.scrollLeft / sparent.scrollWidth) * sparent.scrollWidth;
        }
        const start = (e) => {
            isDown = true;
            slider.classList.remove('hhc-smooth');
            startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        }
        const move = (e) => {
            if(!isDown) return;
            // e.preventDefault();
            const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            const dist = (x - startX) * 1.9;
            slider.scrollLeft = scrollLeft - dist;
        }
        const goLeft = (e) => {
            e.preventDefault();
            // goTop();
            slider.scrollLeft = (Math.round(slider.scrollLeft / sparent.scrollWidth) * sparent.scrollWidth) - sparent.scrollWidth;
        }
        const goRight = (e) => {
            e.preventDefault();
            // goTop();
            slider.scrollLeft = (Math.round(slider.scrollLeft / sparent.scrollWidth) * sparent.scrollWidth) + sparent.scrollWidth;
        }
        const sresize = () => {
            slider.scrollLeft = 0;
        }
        // const goTop = () => {
        //     document.body.scrollTop = 0;
        //     document.documentElement.scrollTop = 0;
        // }
        (() => {
            slider.addEventListener('mousedown', start);
            slider.addEventListener('touchstart', start);
            slider.addEventListener('mousemove', move);
            slider.addEventListener('touchmove', move);
            slider.addEventListener('mouseleave', end);
            slider.addEventListener('mouseup', end);
            slider.addEventListener('touchend', end);
            slider.addEventListener('resize', sresize);
            btleft.addEventListener('click', goLeft);
            btright.addEventListener('click', goRight);
        })();
    });
</script>

<style scoped>
    section{
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .hdf-image{
        position: relative;
        width: 100%;
        display: flex;
        height: auto;
        overflow: hidden;
        user-select: none;
        background: rgb(255, 255, 255);
        transition: 0.2s;
    }
    .hh-carousel{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        transition: 0.2s;
    }
    .hhc-smooth{
        scroll-behavior: smooth;
    }
    .hc-part{
        position: relative;
        display: inline-block;
        /* min-width: 100%; */
        width: 20%;
        height: 350px;
        /* border: 1px solid green; */
        transition: 0.2s;
        margin-bottom: 2rem;
        padding: 1rem;
    }
    .hcb-button{
        position: absolute;
        top: 45%;
        z-index: 5;
        padding: 1rem;
        background: lightgrey;
        color: grey;
        display: none;
    }
    .hcb-left{
        left: 1rem;
    }
    .hcb-right{
        right: 1rem;
    }
    .fullscreen-button{
        display: none;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 5;
        cursor: pointer;
        color: rgba(255, 187, 0, 0.534);
        padding: 1rem;
    }
    .hcp-inner{
        position: relative;
        display: flex;
        height: 100%;
        overflow: hidden;
        justify-content: center;
    }
    .hcp-inner img{
        max-width: 100%;
        max-height: 100%;
    }
    .img-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .hcp-description{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        /* background: rgba(0, 0, 0, 0.432); */
        color: #ffffff;
        padding: 1rem;
        /* padding-left: 5%;
        padding-right: 5%; */
        /* display: none; */
    }
    .hcp-description-inner{
        background: rgba(0, 0, 0, 0.432);
        padding: 1rem;
    }
    .hcp-description-inner p{
        color: #ffffff;
    }
    @media only screen and (max-width: 1500px){
        /* .hdf-image{
            height: 32rem;
        } */
        .hc-part{
            width: 25%;
        }
    }
    @media only screen and (max-width: 1050px){
        /* .hdf-image{
            height: 26rem;
        } */
        .hc-part{
            width: 33.33%;
        }
        .hh-left{
            padding-top: 2rem;
            padding-right: 2rem;
        }
        .hh-left h1{
            font-size: 2.6rem;
        }
    }
    @media only screen and (max-width: 750px){
        .hdf-image{
            height: 32rem;
            background: #ffffff;
        }
        .hh-carousel{
            flex-wrap: nowrap;
        }
        .hcb-button{
            display: inline;
        }
        .hc-part{
            min-width: 100%;
            width: 100%;
            height: 100%;
        }
        .fullscreen-button{
            /* display: inline; */
        }
    }
</style>