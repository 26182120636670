<template>
    <HeroText 
        blueTheme
        subTitle="Our Great Services"
        :title="service.name"
    />
    <ContentImages
        noBackground                
        :paragraph="service.description"        
    >
        <img :src="service.img_url" alt="services" class="img-fluid" style="object-fit: cover;">
    </ContentImages>
    <div class="container-fluid background-cream">
      <div class="container py-5 row mx-auto gap-4">
        <div class="col-sm-12">
            <span class="content-sub-title text-start fw-bold">Related Services</span>
        </div>
        <div class="col-sm-12 row">
            <template v-for="related_service in getRelatedServices.slice(0, 2)" :key="related_service.service_id">
                <div class="col-sm-6" v-if="related_service.category">
                    <HeadingContent
                        blue_title
                        text_dark
                        :subTitle="related_service.category.name" 
                        :title="related_service.name"
                        :paragraph="related_service.description"
                        :headingLink="'/service-detail/'+related_service.service_id"
                    />
                </div>
            </template>
        </div>
        <div class="col-sm-12">
            <a href="/our-services" class="btn btn-primary link-to-website"><i class="bi bi-chevron-left"></i> Back to Service</a>
        </div>
      </div>
    </div>
    <FooterContent/>
</template>

<script>
    import axios from "axios"
    import FooterContent from "@/components/utils/FooterContent.vue"
    import HeroText from "@/components/utils/HeroText.vue"
    import ContentImages from "@/components/utils/ContentImages.vue"
    import HeadingContent from "@/components/utils/HeadingContent.vue"
    export default {
        components: {
            FooterContent, HeroText, ContentImages, HeadingContent
        },
        data() {
            return {
                service: [],
                service_id: null,
                service_category_id: null,
                related_service: [],
            }
        },
        mounted() {
            window.scrollTo(0, 0);
            axios.get(`https://backend2.melindadev.com/api/service/${this.$route.params.id}`).then(
                response => { 
                    this.service = response.data.service;
                    this.service_id = response.data.service_id;
                    this.service_category_id = response.data.service.category.service_categori_id;
                }
            );
            axios.get('https://backend2.melindadev.com/backend2/public/api/service').then(
                response => this.related_service = response.data.services
            )
        },
        computed: {
            getRelatedServices() {
                return this.related_service.filter(result => {
                    return result.service_categori_id === this.service_category_id && result.service_id != this.service_id
                })
            }
        }
    }
</script>

<style scoped>
    .content-sub-title {
        color: #1B4674 !important;
    }
    .link-to-website {
        background: #ffffff;
        border-radius: 40px;
        border: 1px solid #FCE8CE;
        color: #575757;
    }
    .link-to-website:hover {
        background: #FCE8CE;
        border-radius: 40px;
        color: #575757;
        border: 1px solid transparent;
    }
</style>