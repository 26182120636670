<script>
  import JumbotronComps from "@/components/home/JumbotronComps.vue"
  import ListService from "@/components/home/ListService.vue"
  import CarouselComps from "@/components/home/CarouselComps.vue"
  import CarouselService from "@/components/home/CarouselService.vue"
  // import VideoCardHomeComponent from "@/components/home/VideoCardHomeComponent.vue"
  import VideoSlideComponent from "@/components/home/VideoSlideComponent.vue"
  // import ArticlesComps from "@/components/home/ArticlesComps.vue"
  import ArticleComponent from '@/components/home/ArticleComponent.vue';
  import FooterContent from "@/components/utils/FooterContent.vue"  
  import axios from "axios"
  // import { BASE_URL } from "@/helpers/api"
  export default {
    name: 'HomeView',
    components: { JumbotronComps, ListService, CarouselComps, CarouselService, VideoSlideComponent, ArticleComponent, FooterContent },
    data() {
      return {       
        data_article: [],
        contact: [],
        services: [],
        data_services: [],
        data_point: [],
        articles: [],
      }
    },
    methods: {
      loadHomeAPI() {
        axios.all([
          axios.get('https://backend2.melindadev.com/api/home/banner'),
          axios.get('https://backend2.melindadev.com/api/contact-us'),
          axios.get('https://backend2.melindadev.com/api/service'),
          axios.get('https://backend2.melindadev.com/api/home'),
        ]).then(response => {        
          sessionStorage.setItem('data_article', JSON.stringify(response[0].data.carousel1));
          sessionStorage.setItem('contact', JSON.stringify(response[1].data.contact_us));
          sessionStorage.setItem('services', JSON.stringify(response[2].data.services));
          sessionStorage.setItem('data_services', JSON.stringify(response[3].data.services));
          sessionStorage.setItem('data_point', JSON.stringify(response[3].data.point));
          sessionStorage.setItem('articles', JSON.stringify(response[3].data.news));

          this.data_article = JSON.parse(sessionStorage.getItem('data_article'));
          this.contact = JSON.parse(sessionStorage.getItem('contact'));
          this.services = JSON.parse(sessionStorage.getItem('services'));
          this.data_services = JSON.parse(sessionStorage.getItem('data_services'));
          this.data_point = JSON.parse(sessionStorage.getItem('data_point'));
          this.articles = JSON.parse(sessionStorage.getItem('articles'));
        });
      }
    },
    mounted() {
      window.scrollTo(0, 0);
      sessionStorage.getItem('data_article') === null ? this.loadHomeAPI() : this.data_article = JSON.parse(sessionStorage.getItem('data_article'));
      sessionStorage.getItem('contact') === null ? this.loadHomeAPI() : this.contact = JSON.parse(sessionStorage.getItem('contact'));
      sessionStorage.getItem('services') === null ? this.loadHomeAPI() : this.services = JSON.parse(sessionStorage.getItem('services'));
      sessionStorage.getItem('data_services') === null ? this.loadHomeAPI() : this.data_services = JSON.parse(sessionStorage.getItem('data_services'));
      sessionStorage.getItem('data_point') === null ? this.loadHomeAPI() : this.data_point = JSON.parse(sessionStorage.getItem('data_point'));
      sessionStorage.getItem('articles') === null ? this.loadHomeAPI() : this.articles = JSON.parse(sessionStorage.getItem('articles'));      
    },
  }
</script>

<template>  
  <JumbotronComps :data_article="data_article" :contact="contact" />    
  <ListService :services="services" />
  <CarouselService :data_services="data_services" />
  <CarouselComps :data_point="data_point" />
  <VideoSlideComponent />
  <!-- <ArticlesComps :articles="articles" /> -->
  <ArticleComponent/>
  <FooterContent />
</template>