<template>
    <div class="container-fluid row m-0 p-0 background-cream">
        <div class="col-sm-6 align-self-center">
            <HeroText 
                :subTitle="quality_policy.sub_title"
                :title="quality_policy.title"
                :content="quality_policy.description"
                textDark
            />
        </div>
        <img src="https://images.unsplash.com/photo-1624976845869-59781225b4d0?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871" alt="images" class="col-sm-6 pe-0">
    </div>
</template>

<script>
import axios from "axios";
import HeroText from "@/components/utils/HeroText.vue";
export default {
    components: {
        HeroText
    },
    data(){
        return {
            quality_policy: []
        }
    },
    mounted(){
        axios.get('https://backend2.melindadev.com/api/about').then(
            response => this.quality_policy = response.data.quality_policy
        )
    }
}
</script>

<style scoped>
    .text-dark p {
        color: #575757 !important;
    }
</style>
