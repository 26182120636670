<script>
    import axios from "axios";
    import FooterContent from '@/components/utils/FooterContent.vue'
    import HeroText from "@/components/utils/HeroText.vue"
    import GalleryComponent from "@/components/gallery-detail/GalleryComponent.vue";
    // import CardGallery from "@/components/gallery-detail/CardGallery.vue"   
    export default {
        data() {
            return {
                title:null,
                content:null,
                subTitle:null,
            }
        },
        components: {
            FooterContent,
            GalleryComponent,
            // CardGallery,
            HeroText
        },
        mounted() {
            axios.get('https://backend2.melindadev.com/api/art/'+this.$route.params.id)
            .then(response => {
                this.title = response.data.art.title;
                this.content = response.data.art.description;
                this.subTitle = response.data.art.category.name;
            })
        },
    }
</script>

<template>
    <div class="container row mx-auto px-0">
        <div class="col-sm-6">
            <HeroText 
                :title="title"
                :subTitle="subTitle"
            />
        </div>
        <div class="col-sm-6 d-flex justify-content-end align-self-center">
            <a href="/gallery" class="btn btn-primary rounded-pill">
                Back to Gallery
            </a>
        </div>
    </div>
    <GalleryComponent/>
    <!-- <CardGallery /> -->
    <FooterContent />
</template>


<style scoped>
    .btn-primary {
        background-color: white;
        border: 1px solid #FCE8CE;
        color: #545454;
    }
    .btn-primary:hover {
        background-color: white;
        border: 1px solid #FCE8CE;
        color: #545454;
        opacity: 0.5;
    }
</style>