<template>    
    <div class="container hospital-nearby my-4">
        <HeadingContent 
            subTitle="Melinda Hospital Articles"
            title="Latest News"
            no_text
        />        
        <div class="row">
            <template v-for="article in articles" v-bind:key="article.article_id">
                <div class="col-sm-4">
                    <article class="card text-white border-0" v-if="article.category.name">
                        <template v-if="article.thumb_small">
                            <img :src="article.thumb_small" class="article-img card-img" :alt="article.name">
                        </template>
                        <template v-else>
                            <img src="https://dummyimage.com/600x400/9c9c9c/ffffff&text=Gambar+Tidak+Ada" class="article-img card-img" :alt="article.name">
                        </template>
                        <div class="card-img-overlay card-article-body">
                            <span class="badge bg-primary">{{article.category.name}}</span>
                            <h5 class="card-title mt-2">
                                <a :href="`https://melindahospital.com/articles/detail/${article.slug}`" target="_blank">
                                    {{article.name}}
                                </a>
                                <!-- <router-link :to="{ name: 'artikel', params: { id: article.slug } }" >
                                    {{article.name}}
                                </router-link> -->
                            </h5>                         
                        </div>
                    </article>
                </div>
            </template>
        </div>
    </div>   
</template>

<script setup>
    import HeadingContent from "@/components/utils/HeadingContent.vue";
    import { computed } from "@vue/reactivity";
    import { useArticleStore } from "@/stores/article";
    const articles = computed(() => useArticleStore().homeArticles);
    useArticleStore().setHomeComponents();
    // export default {
    //     components: { HeadingContent },
    //     props: {
    //         articles: Array
    //     },
    //     data(){
    //         return {                
    //             length_articles: null,
    //         }
    //     },
    // }
</script>



<style scoped>
    .badge.bg-primary {
        background-color: #1B4674 !important;
    }
    img.article-img{
        width: 100%;
        height: 320px !important;
        background-size: cover;
        object-fit: cover;
    }
    .card {
        height: fit-content;
    }        
    .card-article-body {
        margin-top: auto;
        background-image: linear-gradient(rgba(34, 34, 34, 0.808), rgb(36, 36, 36), rgb(34, 34, 34));
        height: 40%;     
    }    
    .card-article-body h5 a {
        color: white;
        width: 100% !important;
        font-size: 20px;
        font-weight: bold !important;
        text-decoration: none;    
    }
    .card-article-body h5 a:hover {
        color: white;
        width: 100% !important;
        font-size: 20px;
        font-weight: bold !important;
        text-decoration: none;
        opacity: 0.5;    
    }
    @media (max-width: 1199.98px) {
        footer #contact {        
            padding: 48px 8% 48px 8%;
            justify-content: space-between;
            background-color: #FEF8F0;        
        }
        footer hr { width: 84%; }
    }
</style>